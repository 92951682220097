import {
  LOAN_OPTION_TYPES_NUMERICAL_VALUE,
  VA_History,
  WAIVE_ESCROW_OPTIONS,
  PECreditGradeOptions,
  PEMonthsOptions,
  CUSTOM_PARAMETER_TYPES,
  yesNoOptions,
  PEImpoundOptions,
} from '@shared/constants';

import {
  defaultLoanPurposeOptions,
  defaultMiPaidByOptions,
  defaultOccupancyOptions,
  defaultPropertyTypeOptions,
  defaultTemporaryBuydownTypeOptions,
  defaultAusOptions,
  defaultLienPositionOptions,
  defaultRefinanceTypeOptions,
  defaultLockPeriodOptions,
} from '@pe/components/Pricing/defaults.js';
import { filterObject } from '@shared/utils/helpers.js';

const generateTranslationMap = options => {
  return options.map(({ id, text }) => ({
    [text]: id,
  }));
};

export const extractOptions = translationSchema => {
  const options = {};

  for (const key in translationSchema) {
    if (translationSchema[key].map) {
      options[key] = translationSchema[key].map.map(option => ({
        id: Object.values(option)[0],
        text: Object.keys(option)[0],
      }));
    } else if (typeof translationSchema[key].example === 'boolean') {
      options[key] = [
        { id: true, text: true },
        { id: false, text: false },
      ];
    }
  }

  return options;
};

const loanOptions = Object.keys(LOAN_OPTION_TYPES_NUMERICAL_VALUE);
const vaHistoryOptions = Object.keys(VA_History);

// Removes disabled fields from the translation schema so the LLM doesn't attempt to translate them
export const filterSchema = (schema, template) => {
  let filteredSchema = {};
  const disabledRemovedKeys = [];

  if (!template || Object.keys(template).length === 0) {
    return { filteredSchema, disabledRemovedKeys };
  }

  for (const key in schema) {
    if (!template[key]?.disabled) {
      filteredSchema[key] = schema[key];
    } else {
      disabledRemovedKeys.push(key);
    }
  }

  filteredSchema = filterObject(filteredSchema, param => !param?.disabled);

  return { filteredSchema, disabledRemovedKeys };
};

const getCurrentValue = (currentPricingParameters, template, key) => {
  return currentPricingParameters?.[key]?.value ?? template?.[key]?.value ?? '';
};

// We only show the true values in currentValue for programs since the model only includes true values when translating programs
const retrieveSearchProgramCurrentValue = (
  currentPricingParameters,
  template,
  key,
) => {
  const programs = currentPricingParameters?.[key] || template?.[key] || {};
  const currentValue = {};
  Object.keys(programs).forEach(program => {
    if (!programs[program].value) return;
    currentValue[program] = programs[program].value;
  });

  return currentValue;
};

export const generateLoanTranslationSchema = (
  template = {},
  customParameters = {},
  currentPricingParameters = {},
) => {
  return {
    purpose: {
      description: 'The purpose of the loan.',
      example: 1,
      map: generateTranslationMap(
        template.loanPurposeOptions || defaultLoanPurposeOptions,
      ),
    },
    applicationDate: {
      description:
        'The date of the loan application (When borrower is applying).',
      example: '2023-11-15T14:12',
      currentDatetime: new Date().toISOString().substring(0, 16),
    },
    amount: {
      description: 'The amount of the loan.',
      example: 600000,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'amount',
      ),
      derived: 'purchasePrice and down payment amount or %',
    },
    purchasePrice: {
      description: 'The purchase price of the property.',
      example: 800000,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'purchasePrice',
      ),
    },
    downPayment: {
      description: 'The down payment amount.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'downPayment',
      ),
    },
    ltv: {
      description: 'The loan-to-value ratio.',
      example: 0,
      currentValue: getCurrentValue(currentPricingParameters, template, 'ltv'),
    },
    position: {
      description: 'The lien position of the loan. (Heloc, etc.)',
      example: 1,
      map: generateTranslationMap(
        template?.lienPositionOptions || defaultLienPositionOptions,
      ),
    },
    isSecondCommunityLoan: {
      description: 'Whether or not the loan is a second community loan.',
      example: false,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'isSecondCommunityLoan',
      ),
    },
    isSecondPiggyback: {
      description: 'Whether or not the loan is a second piggyback loan.',
      example: false,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'isSecondPiggyback',
      ),
    },
    isSecondInvestorSameAsFirst: {
      description:
        'Whether or not the second investor is the same as the first.',
      example: false,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'isSecondInvestorSameAsFirst',
      ),
    },
    propertyValue: {
      description: 'The value of the property.',
      example: 800000,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'propertyValue',
      ),
    },
    refinancePurpose: {
      description: 'The purpose of the refinance.',
      example: 0,
      map: generateTranslationMap(
        template.refinanceTypeOptions || defaultRefinanceTypeOptions,
      ),
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'refinancePurpose',
      ),
    },
    cashOutAmount: {
      description: 'The amount of cash out.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'cashOutAmount',
      ),
    },
    secondAmount: {
      description: 'The amount of the second loan, or the other lien.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'secondAmount',
      ),
    },
    helocLineAmount: {
      description: 'The amount of the HELOC line.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'helocLineAmount',
      ),
    },
    helocDrawAmount: {
      description: 'The amount of the HELOC draw.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'helocDrawAmount',
      ),
    },
    isMortgageInsurancePaidByBorrower: {
      description:
        'Whether or not the mortgage insurance is paid by the borrower.',
      map: generateTranslationMap(
        template.miPaidByOptions || defaultMiPaidByOptions,
      ),
      example: 1,
    },
    waiveEscrow: {
      description: 'Whether or not to waive escrow.',
      map: generateTranslationMap(
        template.waiveEscrowOptions || WAIVE_ESCROW_OPTIONS,
      ),
      example: 1,
    },
    rollLenderFee: {
      description: 'Whether or not to roll the lender fee.',
      example: false,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'rollLenderFee',
      ),
    },
    temporaryBuydownType: {
      description:
        'Program to reduce mortgage interest rate for a number of years. (ex. 3 years: 3-2-1)',
      map: generateTranslationMap(
        template.temporaryBuydownTypeOptions ||
          defaultTemporaryBuydownTypeOptions,
      ),
      example: 1,
    },
    prepaymentPenaltyPeriodMonths: {
      description:
        'The number of months of the prepayment penalty period (PPP).',
      map: generateTranslationMap(PEMonthsOptions),
      example: 1,
    },
    fhaFinancingOption: {
      description: 'The FHA financing option.',
      map: generateTranslationMap(loanOptions),
      example: loanOptions[0],
    },
    fhaMortgageInsurancePremium: {
      description: 'The FHA mortgage insurance premium.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'fhaMortgageInsurancePremium',
      ),
    },
    fhaMortgageInsurancePremiumAmount: {
      description: 'The amount of the FHA mortgage insurance premium.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'fhaMortgageInsurancePremiumAmount',
      ),
    },
    fhaTotalLoanAmount: {
      description: 'The total amount of the FHA loan.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'fhaTotalLoanAmount',
      ),
    },
    fhaFinanceAmount: {
      description: 'The finance amount of the FHA loan',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'fhaFinanceAmount',
      ),
    },
    usdaFinancingOption: {
      description: 'The USDA financing option',
      map: generateTranslationMap(loanOptions),
      example: 0,
    },
    usdaGuaranteeFee: {
      description: 'The USDA guarantee fee',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'usdaGuaranteeFee',
      ),
    },
    usdaGuaranteeFeeAmount: {
      description: 'The amount of the USDA guarantee fee',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'usdaGuaranteeFeeAmount',
      ),
    },
    usdaFinanceAmount: {
      description: 'The finance amount of the USDA loan',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'usdaFinanceAmount',
      ),
    },
    vaFinancingOption: {
      description: 'The VA financing option',
      map: generateTranslationMap(loanOptions),
      example: 0,
    },
    vaLoanHistory: {
      description: 'The VA loan history.',
      map: generateTranslationMap(vaHistoryOptions),
      example: vaHistoryOptions[0],
    },
    vaFundingFeeAmount: {
      description: 'The amount of the VA funding fee.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'vaFundingFeeAmount',
      ),
    },
    vaFinanceAmount: {
      description: 'The finance amount of the VA loan.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'vaFinanceAmount',
      ),
    },
    vaFundingFeeExempt: {
      description: 'Whether or not the VA funding fee is exempt.',
      example: false,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'vaFundingFeeExempt',
      ),
    },
    prepaymentPenaltyStructureType: {
      description: 'The prepayment penalty structure type',
      example: null,
    },
    prepaymentPenaltyStructure: {
      description: 'The prepayment penalty structure',
      example: null,
    },
    streamlineRefinanceType: {
      description: 'The streamline refinance type',
      example: 'None',
    },
    impounds: {
      map: generateTranslationMap(PEImpoundOptions),
      description: 'The impounds',
      example: 2,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'impounds',
      ),
    },
    aus: {
      description: 'The automated underwriting system being used.',
      map: generateTranslationMap(template.ausOptions || defaultAusOptions),
      example: 1,
    },
    ...createCustomParameterDict(customParameters),
  };
};

export const generatePropertyTranslationSchema = (
  template = {},
  customParameters = {},
  currentPricingParameters = {},
) => {
  return {
    state: {
      description: 'The state abbreviation of the property (WA, AL, TX, etc.)',
      example: 'AL',
      derived: 'city',
    },
    county: {
      description:
        'The county of the property (Autauga, Baldwin, Barbour, etc.)',
      example: 'Autauga',
      derived: 'city',
    },
    countyFipsCode: {
      description: 'The county fips code of the property (033, 003, 005, etc.)',
      example: '033',
      derived: 'county or city',
    },
    addressLine1: {
      description: 'The first line of the property address.',
      example: '123 Main St',
    },
    zipCode: {
      description: 'The zip code of the property.',
      example: '12345',
    },
    city: {
      description: 'The city of the property.',
      example: 'Seattle',
    },
    propertyType: {
      description:
        'The type of property (Condo, Apartment, Single Family etc.)',
      example: 1,
      map: generateTranslationMap(
        template.propertyTypeOptions || defaultPropertyTypeOptions,
      ),
    },
    occupancy: {
      description: 'The type of occupancy for the property.',
      example: 1,
      map: generateTranslationMap(
        template.occupancyOptions || defaultOccupancyOptions,
      ),
    },
    units: {
      description:
        'The number of units in the property (Apartment might have several units, single family home would be 1)',
      example: 1,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'units',
      ),
    },
    stories: {
      description:
        'The number of stories or levels in the property. (Skyscraper might be 100 stories. A single family home might be 1 story.)',
      example: 1,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'stories',
      ),
    },
    isNonWarrantableProject: {
      description:
        'Whether the property is a non-warrantable project (true/false)',
      example: false,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'isNonWarrantableProject',
      ),
    },
    isCondotel: {
      description: 'Whether the property is a condotel (true/false)',
      example: false,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'isCondotel',
      ),
    },
    inspectionWaiver: {
      description: 'Whether to waive inspection (true/false)',
      example: false,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'inspectionWaiver',
      ),
    },
    appraisedValue: {
      description: 'The appraised value of the property',
      example: 800000,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'appraisedValue',
      ),
    },
    estimatedValue: {
      description: 'The estimated value of the property',
      example: 800000,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'estimatedValue',
      ),
    },
    ...createCustomParameterDict(customParameters),
  };
};

export const generateBorrowerTranslationSchema = (
  template = {},
  customParameters = {},
  currentPricingParameters = {},
) => {
  return {
    fico: {
      description: 'The FICO score.',
      example: 750,
      currentValue: getCurrentValue(currentPricingParameters, template, 'fico'),
    },
    dtiRatio: {
      description:
        'The debt-to-income ratio (percent represented as float 20.12% = 20.12)',
      example: 20.12,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'dtiRatio',
      ),
    },
    monthsOfReserves: {
      description: 'The number of months of reserves.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'monthsOfReserves',
      ),
    },
    latePmt30x12: {
      description: 'The number of late payments in the past 12 months.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'latePmt30x12',
      ),
    },
    latePmt60x12: {
      description: 'The number of late payments in the past 24 months.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'latePmt60x12',
      ),
    },
    latePmt90x12: {
      description: 'The number of late payments in the past 36 months.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'latePmt90x12',
      ),
    },
    latePmt120x12: {
      description: 'The number of late payments in the past 48 months.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'latePmt120x12',
      ),
    },
    latePmt30x24: {
      description: 'The number of late payments in the past 24 months.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'latePmt30x24',
      ),
    },
    latePmt60x24: {
      description: 'The number of late payments in the past 48 months.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'latePmt60x24',
      ),
    },
    latePmt90x24: {
      description: 'The number of late payments in the past 72 months.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'latePmt90x24',
      ),
    },
    latePmt120x24: {
      description: 'The number of late payments in the past 96 months.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'latePmt120x24',
      ),
    },
    dEMultEvents: {
      description:
        'Whether or not the borrower has multiple derogatory events. (true/false)',
      example: 'false',
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'dEMultEvents',
      ),
    },
    dEMultBK: {
      description:
        'Whether or not the borrower has had multiple bankruptcies. (true/false)',
      example: 'false',
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'dEMultBK',
      ),
    },
    dEMonthsBKC7: {
      description:
        'The number of months since the last chapter 7 (liquidation) bankruptcy.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'dEMonthsBKC7',
      ),
    },
    dEMonthsBKC11: {
      description: 'The number of months since the last chapter 11 bankruptcy',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'dEMonthsBKC11',
      ),
    },
    dEMonthsBKC13: {
      description: 'The number of months since the last chapter 13 bankruptcy',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'dEMonthsBKC13',
      ),
    },
    dEMonthsChargeOff: {
      description: 'The number of months since debt was charged off.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'dEMonthsChargeOff',
      ),
    },
    dEMonthsDIL: {
      description: 'The number of months since deed in lieu of foreclosure.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'dEMonthsDIL',
      ),
    },
    dEMonthsForeclosure: {
      description: 'The number of months since foreclosure.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'dEMonthsForeclosure',
      ),
    },
    dEMonthsLoanMod: {
      description: 'The number of months since the last loan modification.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'dEMonthsLoanMod',
      ),
    },
    dEMonthsNoticeOfDefault: {
      description: 'The number of months since a notice of default was issued.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'dEMonthsNoticeOfDefault',
      ),
    },
    dEMonthsShortSale: {
      description: 'The number of months since a short sale has occurred.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'dEMonthsShortSale',
      ),
    },
    isNonOccupancyBorrower: {
      description: 'Whether or not the borrower is a non-occupancy borrower.',
      example: false,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'isNonOccupancyBorrower',
      ),
    },
    isNonOccupancyCoborrower: {
      description: 'Whether or not the coborrower is a non-occupancy borrower.',
      example: false,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'isNonOccupancyCoborrower',
      ),
    },
    propertiesOwned: {
      description: 'The number of properties owned.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'propertiesOwned',
      ),
    },
    isSelfEmployed: {
      description: 'Whether or not the borrower is self-employed (true/false)',
      example: false,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'isSelfEmployed',
      ),
    },
    multipleBorrowerPairs: {
      description:
        'Whether or not the loan contains multiple pairs of borrower/co-borrower applicants (true/false)',
      example: false,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'multipleBorrowerPairs',
      ),
    },
    verificationMethod: {
      description: 'The verification method.',
      map: generateTranslationMap(template.verificationMethodOptions || []),
      example: 1,
    },
    creditGrade: {
      description: 'The credit grade.',
      map: generateTranslationMap(
        template.creditGradeOptions || PECreditGradeOptions,
      ),
      example: 1,
    },
    isNonTraditionalCredit: {
      description: 'Whether or not the credit is non-traditional.',
      example: false,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'isNonTraditionalCredit',
      ),
    },
    isGiftFunds: {
      description: 'Whether or not the funds are a gift.',
      example: false,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'isGiftFunds',
      ),
    },
    residualIncome: {
      description: 'The residual income.',
      example: 0,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'residualIncome',
      ),
    },
    housingExpenseRatio: {
      description: 'The housing expense ratio.',
      example: '',
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'housingExpenseRatio',
      ),
    },
    isFirstTimeHomeBuyer: {
      description: 'Whether or not the borrower is a first-time home buyer.',
      example: false,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'isFirstTimeHomeBuyer',
      ),
    },
    investorExperience: {
      description: 'The investor experience.',
      map: generateTranslationMap(template.investorExperienceOptions || []),
      example: 1,
    },
    months1099: {
      description: 'The number of months of 1099 income.',
      example: 12,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'months1099',
      ),
    },
    cpaPandLMonths: {
      description: 'The number of months of CPA, P&L (Profit and Loss).',
      example: 24,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'cpaPandLMonths',
      ),
    },
    fullDocMonths: {
      description: 'The number of months of full documentation.',
      example: 24,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'fullDocMonths',
      ),
    },
    bankStatementsNumberOfMonthsBusiness: {
      description:
        'The number of MONTHS of business years for bank statement verification (usually 1,2 years or 12, 24 months).',
      example: 24,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'bankStatementsNumberOfMonthsBusiness',
      ),
    },
    bankStatementsNumberOfMonthsPersonal: {
      description:
        'The number of MONTHS of personal years for bank statement verification (usually 1,2 years or 12, 24 months).',
      example: 24,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'bankStatementsNumberOfMonthsPersonal',
      ),
    },
    verificationOfEmploymentAmount: {
      description: 'The verification of employment amount. (VOE)',
      example: 2000,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'verificationOfEmploymentAmount',
      ),
    },
    assetQualificationAmount: {
      description: 'The asset qualification amount.',
      example: 2000,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'assetQualificationAmount',
      ),
    },
    debtServiceCoverageRatio: {
      description: 'The debt service coverage ratio. (DSCR) 240% = 2.4',
      example: 2.4,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'debtServiceCoverageRatio',
      ),
    },
    income: {
      description: 'The monthly income of the borrower.',
      example: 5000,
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'income',
      ),
    },
    ...createCustomParameterDict(customParameters),
  };
};

export const generateSearchTranslationSchema = (
  template = {},
  currentPricingParameters = {},
) => {
  return {
    conventional: {
      description:
        'Loan Type: Whether conventional loans are being considered (true/false)',
      example: 'true',
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'conventional',
      ),
    },
    jumbo: {
      description:
        'Loan Type: Whether jumbo loans are being considered (true/false)',
      example: 'true',
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'jumbo',
      ),
    },
    nonQm: {
      description:
        'Loan Type: Whether nonQm loans are being considered (true/false). nonQM loans involve alternative qualification and verification methods such as bank statements, full doc, verification of employment, 1099, asset qualification, CPA P&L, etc.',
      example: 'true',
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'nonQm',
      ),
    },
    usda: {
      description:
        'Loan Type: Whether usda loans are being considered (true/false)',
      example: 'true',
      currentValue: getCurrentValue(currentPricingParameters, template, 'usda'),
    },
    va: {
      description:
        'Loan Type: Whether va loans are being considered (true/false)',
      example: 'true',
      currentValue: getCurrentValue(currentPricingParameters, template, 'va'),
    },
    fha: {
      description:
        'Loan Type: Whether fha loans are being considered (true/false)',
      example: 'true',
      currentValue: getCurrentValue(currentPricingParameters, template, 'fha'),
    },
    heloc: {
      description:
        'Loan Type: Whether heloc loans are being considered (true/false)',
      example: 'true',
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'heloc',
      ),
    },
    desiredLockPeriod: {
      description:
        'The desired number of days for the Lock Period of the Loan.',
      example: '30',
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'desiredLockPeriod',
      ),
      map: generateTranslationMap(
        template?.lockPeriodOptions || defaultLockPeriodOptions,
      ),
    },
    interestOnly: {
      description:
        'Whether or not to include interest-only products (true/false)',
      example: 'true',
      currentValue: getCurrentValue(
        currentPricingParameters,
        template,
        'interestOnly',
      ),
    },
    armPrograms: {
      description:
        'The adjustable-rate mortgage programs being considered. Set empty {} to remove. (1 year = 12 months, 3 years = 36 months, 5 years = 60 months, 7 years = 84 months, 10 years = 120 months)',
      example: '{"12":true,"120":true}',
      currentValue: retrieveSearchProgramCurrentValue(
        currentPricingParameters,
        template,
        'armPrograms',
      ),
      map: [
        { '1 Year': 12 },
        { '3 Years': 36 },
        { '5 Years': 60 },
        { '7 Years': 84 },
        { '10 Years': 120 },
      ],
    },
    loanPrograms: {
      description:
        'AKA Fixed Programs. The length of the loan term(in months) programs being considered. Set empty {} to remove. (10 years = 120 months, 15 years = 180 months, 20 years = 240 months, 25 years = 300 months, 30 years = 360 months)',
      example: '{"120":true,"360":true}',
      currentValue: retrieveSearchProgramCurrentValue(
        currentPricingParameters,
        template,
        'loanPrograms',
      ),
      map: [
        { '10 Years': 120 },
        { '15 Years': 180 },
        { '20 Years': 240 },
        { '25 Years': 300 },
        { '30 Years': 360 },
      ],
    },
  };
};

const createCustomParameterDict = customParameters => {
  const customParameterDict = {};

  for (const param of Object.values(customParameters)) {
    // Currently Skipping custom parameters with very large option sets to prevent context overload
    if (
      Array.isArray(param?.allowedValues) &&
      param.allowedValues.length > 100
    ) {
      continue;
    }

    customParameterDict[param.name] = {
      description: 'Refer to the name of the parameter',
      example: param.allowedValues.length ? param.allowedValues[0] : '',
    };

    if (param.allowedValues.length) {
      customParameterDict[param.name]['map'] = generateTranslationMap(
        param.allowedValues.map(option => ({ id: option, text: option })),
      );
    }

    if (param.type === CUSTOM_PARAMETER_TYPES.BOOLEAN) {
      customParameterDict[param.name]['map'] =
        generateTranslationMap(yesNoOptions);
    }

    if (param?.disabled) {
      customParameterDict[param.name]['disabled'] = true;
    }
  }

  return customParameterDict;
};
