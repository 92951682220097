import { isClampCategory } from '@pe/services/rules.js';
import { log, LOG_LEVEL } from '@shared/utils/logging.js';
import Vue from 'vue';
import clone from 'lodash/cloneDeep';
import {
  CUSTOM_PARAMETER_TYPES,
  CUSTOM_PARAMETER_TYPES_CONFIG,
  PEDecimal,
  PEEligibilityType,
  PEEligibilityValue,
  PEEnumCast,
  PEInteger,
  PEDateTime,
  PEPropertyStateEnumName,
  PERuleCategories,
  PERuleFieldsChannel,
  PERuleFieldsCustomValue,
  PERuleFieldsCountyFipsCode,
  PERuleFieldsState,
  PERuleOperations,
  PERuleStringEscape,
  PERuleValueListPurpose,
  RULE_TARGET,
  yesNoOptions,
  PERuleFieldsCounty,
  PERuleGroupProperty,
  STATES,
  ADJUSTMENT_UNITS,
  PERuleFieldApplicationDate,
  PERuleFieldFHAPriorEndorsementDate,
  PERuleGroupLoan,
  WORKFLOW_POLICY_DISPLAY,
  PEMIRuleCategories,
  PERuleCalculationOperations,
} from '@shared/constants';
import moment from 'moment';

export const TIME_DISPLAY_FORMAT = 'hh:mm a';
export const TIME_ISO_FORMAT = 'HH:mm:ss';
export const DATE_DISPLAY_FORMAT = 'M/D/YYYY';
export const DATE_ISO_FORMAT = 'YYYY-MM-DD';
export const DATETIME_DISPLAY_FORMAT =
  DATE_DISPLAY_FORMAT + ', ' + TIME_DISPLAY_FORMAT;

export const PEFieldType = Object.freeze({
  unknown: 'Unknown',
  integer: 'Integer',
  decimal: 'Decimal',
  percent: 'percent',
  days: 'Days',
  months: 'Months',
  string: 'String',
  boolean: 'Boolean',
  object: 'Object',
  list: 'List',
  grid: 'Grid',
  datetime: 'DateTime',
  county: 'County',
});

export const PERuleOperandType = Object.freeze({
  Value: 'value',
  List: 'list',
  Field: 'field',
  Grid: 'grid',
  ValueGroup: 'valueGroup',
});

export const PERuleBooleanOperator = Object.freeze({
  And: 'and',
  Or: 'or',
});

export const PERuleBehavior = Object.freeze({
  Adjust: 'Adjust',
  Filter: 'Filter',
});

export const PEClampType = Object.freeze({
  TotalPrice: 'TotalPrice',
  Adjustment: 'Adjustment',
  Margin: 'Margin',
  SRP: 'SRP',
  NoteRate: 'NoteRate',
});

export const PEClampTypeDisplay = Object.freeze({
  TotalPrice: 'total price',
  Adjustment: 'total LLPA',
  Margin: 'total margin',
  SRP: 'total SRP',
  NoteRate: 'note rate',
});

export const PEClampResultsDisplay = Object.freeze({
  [PEClampType.TotalPrice]: 'Total Price',
  [PEClampType.Adjustment]: 'LLPA',
  [PEClampType.Margin]: 'Margin',
  [PEClampType.SRP]: 'SRP',
  [PEClampType.NoteRate]: 'Note Rate',
});

export const CLAMP_RULE_DIMENSIONS = {
  DOLLARS: 'dollars',
};

export const DATE_TIME_SELECTOR_TABS = {
  DATE: 'DATE',
  TIME: 'TIME',
  DATETIME: 'DATETIME',
  DAY: 'DAY',
};

export const DATE_TIME_DAY_OF_WEEK = {
  Sunday: 'DayOfWeek.Sunday',
  Monday: 'DayOfWeek.Monday',
  Tuesday: 'DayOfWeek.Tuesday',
  Wednesday: 'DayOfWeek.Wednesday',
  Thursday: 'DayOfWeek.Thursday',
  Friday: 'DayOfWeek.Friday',
  Saturday: 'DayOfWeek.Saturday',
};

export const DATE_TIME_OPTIONS = [
  { id: DATE_TIME_DAY_OF_WEEK.Sunday, text: 'Sun' },
  { id: DATE_TIME_DAY_OF_WEEK.Monday, text: 'Mon' },
  { id: DATE_TIME_DAY_OF_WEEK.Tuesday, text: 'Tue' },
  { id: DATE_TIME_DAY_OF_WEEK.Wednesday, text: 'Wed' },
  { id: DATE_TIME_DAY_OF_WEEK.Thursday, text: 'Thu' },
  { id: DATE_TIME_DAY_OF_WEEK.Friday, text: 'Fri' },
  { id: DATE_TIME_DAY_OF_WEEK.Saturday, text: 'Sat' },
];

export const IsClampInDollarsOptions = [
  {
    label: ADJUSTMENT_UNITS.BASISPOINTS_PRICE,
    value: ADJUSTMENT_UNITS.BASISPOINTS_PRICE,
  },
  { label: CLAMP_RULE_DIMENSIONS.DOLLARS, value: ADJUSTMENT_UNITS.DOLLARS },
];

export const DEFAULT_CLAMP_MAX_PRICE = 105;

export const MIN_MAX_DOLLARS_TEXT = 'dollars';

export const COMBINED_STATE_COUNTY_FIELD = `${PERuleGroupProperty}.${PERuleFieldsState}+"-"+${PERuleGroupProperty}.${PERuleFieldsCounty}`;
export const COUNTY_FIELD = `${PERuleGroupProperty}.${PERuleFieldsCounty}`;

export const NULLABLE_DATE_TIME_FIELDS = [
  `${PERuleGroupLoan}.${PERuleFieldApplicationDate}`,
  `${PERuleGroupLoan}.${PERuleFieldFHAPriorEndorsementDate}`,
];

const NULL_CHECK_CLAUSE = [
  {
    field: '#.HasValue',
    operation: PERuleOperations.EqualTo.operation,
  },
  {
    value: true,
    operation: PERuleBooleanOperator.And,
  },
];

export const TIME_DATE_ONLY_REGEX = /DateOnly|TimeOnly/;

export function typeForField(operand, ruleFields) {
  if (operand.kind === PERuleOperandType.Grid) {
    return PEFieldType.grid;
  }

  const field = operand.content || operand.field;
  return getType(field, ruleFields);
}

export function getType(field, ruleFields) {
  if (!field) {
    return PEFieldType.unknown;
  } else if (typeof field == 'object') {
    return PEFieldType.integer;
  } else if (field === `${PERuleGroupProperty}.${PERuleFieldsCounty}`) {
    return PEFieldType.county;
  }

  const fieldType = getFieldOptions(field, ruleFields);

  if (fieldType === PEInteger) {
    return PEFieldType.integer;
  } else if (fieldType === PEDecimal) {
    return PEFieldType.decimal;
  } else if (fieldType === String) {
    return PEFieldType.string;
  } else if (fieldType === Boolean) {
    return PEFieldType.boolean;
  } else if (fieldType === PEDateTime) {
    return PEFieldType.datetime;
  } else if (fieldType instanceof Object) {
    if (Array.isArray(fieldType)) {
      return PEFieldType.list;
    }
    return PEFieldType.object;
  } else {
    return PEFieldType.unknown;
  }
}

export function getFieldOptions(field, ruleFields) {
  const { entityName, propertyName } = ruleFields.parseField(field);
  return ruleFields.get(entityName, propertyName);
}

function createRightSideListValues(
  listPurpose,
  fieldType,
  leftSideContent,
  ruleFields,
) {
  const rightSide = {
    kind: PERuleOperandType.List,
  };

  if (listPurpose === PERuleValueListPurpose.Range) {
    rightSide.content = [];
    const value1 = {
      kind: PERuleOperandType.Value,
      type: fieldType,
      content: '',
    };
    const value2 = {
      kind: PERuleOperandType.Value,
      type: fieldType,
      content: '',
    };
    rightSide.content.push(value1, value2);
  } else {
    if (fieldType === PEFieldType.list) {
      rightSide.options = getFieldOptions(leftSideContent, ruleFields);
    }
    rightSide.content = [];
    rightSide.type = fieldType;
  }

  return rightSide;
}

export function createRightSide(
  leftSideContent,
  fieldType,
  content,
  listPurpose,
  ruleFields,
) {
  if (listPurpose) {
    return createRightSideListValues(
      listPurpose,
      fieldType,
      leftSideContent,
      ruleFields,
    );
  }

  const rightSide = {
    kind: PERuleOperandType.Value,
    content: content,
    type: fieldType,
  };

  if (
    typeof content === 'string' &&
    content.match(/TimeOnly|DateOnly|DayOfWeek/)
  ) {
    cleanUpDateTimeValue(rightSide);
  }

  if (
    [PEFieldType.string, PEFieldType.county].includes(fieldType) &&
    rightSide.content
  ) {
    rightSide.content = rightSide.content.replaceAll(PERuleStringEscape, '');
  } else if (fieldType === PEFieldType.list) {
    rightSide.options = getFieldOptions(leftSideContent, ruleFields);
    rightSide.type = leftSideContent;
    if (content && content.includes('.')) {
      const enumType = content.split('.');
      rightSide.enumType = enumType[0];
      content = enumType[1];
    }
    // *Very* special case to satisfy Micro Service where there is
    // no States enumeration.
    if (
      (leftSideContent === PEPropertyStateEnumName ||
        leftSideContent.startsWith(PERuleFieldsCustomValue) ||
        leftSideContent.endsWith(PERuleFieldsCountyFipsCode) ||
        leftSideContent.endsWith(PERuleFieldsChannel)) &&
      rightSide.content
    ) {
      if (Array.isArray(rightSide.content)) {
        rightSide.content.forEach((v, index, arr) => {
          arr[index] = v.replaceAll(PERuleStringEscape, '');
        });
      } else {
        rightSide.content = rightSide.content.replaceAll(
          PERuleStringEscape,
          '',
        );
      }
    } else {
      rightSide.content = content;
    }
  } else if (fieldType === PEFieldType.boolean) {
    rightSide.options = yesNoOptions;
  }
  return rightSide;
}

function cleanUpTerm(term) {
  // FYI: Micro Service *very* special cases.
  // just not to change initial term, making copy

  // Remove potential 'int' casts a field may have
  const termCopy = JSON.parse(JSON.stringify(term));

  if (termCopy.field) {
    termCopy.field = term.field.replaceAll(PEEnumCast, '');

    if (termCopy.field.match(TIME_DATE_ONLY_REGEX)) {
      termCopy.field = termCopy.field.replaceAll(/.*\(|(.Value)?\).*/g, '');
    }
  }

  // Remove casts for customValues
  const cleanUpCustomValue = field => field.replace(/<.*>/, '');
  if (termCopy.field?.startsWith(PERuleFieldsCustomValue)) {
    termCopy.field = cleanUpCustomValue(termCopy.field);
  }
  if (termCopy.listValues?.length) {
    termCopy.listValues
      .filter(value => value.field?.startsWith(PERuleFieldsCustomValue))
      .forEach(value => (value.field = cleanUpCustomValue(value.field)));
  }

  return termCopy;
}

function parseFieldTerm(term, ruleFields, clause, condition) {
  // Remove potential 'int' casts a field may have
  // FYI: Micro Service *very* special case.
  // just not to change initial term, making copy
  const termCopy = cleanUpTerm(term);
  if (termCopy.field === COMBINED_STATE_COUNTY_FIELD) {
    termCopy.field = COUNTY_FIELD;
  }

  const fieldType = typeForField(termCopy, ruleFields);
  const fieldOperand = {
    kind: PERuleOperandType.Field,
    content: termCopy.field,
    type: fieldType,
  };
  if (!clause.left) {
    clause.left = fieldOperand;
    if (condition) {
      clause.condition = condition;
      condition = null;
    }
  } else {
    clause.right = fieldOperand;
  }
}

export function makeDateObjectFromDateString(dateString) {
  const date = new Date();
  const [year, month, day] = dateString.split('-');
  date.setUTCHours(0, 0, 0, 0);
  date.setUTCFullYear(+year, +month - 1, +day);
  return date;
}

export function makeDateObjectFromTimeString(timeString) {
  const date = new Date();
  const [hours, minutes, seconds] = timeString.split(':');
  date.setUTCHours(+hours, +minutes, +seconds, 0);
  return date;
}

function cleanUpDateTimeValue(clause) {
  let value = clause.content;
  value = value.replaceAll(/.*\("|"\)/g, '');

  let date = new Date();
  if (value.match(/^\d{1,2}:\d{1,2}:\d{1,2}$/)) {
    // time string
    date = makeDateObjectFromTimeString(value);
    clause.dateTimeTab = DATE_TIME_SELECTOR_TABS.TIME;
  } else if (value.match(/^\d{2,4}-\d{1,2}-\d{1,2}$/)) {
    // date string
    date = makeDateObjectFromDateString(value);
    clause.dateTimeTab = DATE_TIME_SELECTOR_TABS.DATE;
  } else if (value.match(/^DayOfWeek\..*$/)) {
    clause.dateTimeTab = DATE_TIME_SELECTOR_TABS.DAY;
    return;
  }
  clause.content = date.toISOString();
}

function getValueTermBody(term, ruleFields, clause, isList = false) {
  if (term.value && clause.left) {
    let fieldType;
    if (clause.left.kind === PERuleOperandType.List) {
      fieldType = PEFieldType.integer;
    } else {
      fieldType = typeForField(clause.left, ruleFields);
    }
    return createRightSide(
      clause.left.content,
      fieldType,
      term.value,
      clause.listPurpose,
      ruleFields,
    );
  } else if (term.grid) {
    return {
      kind: PERuleOperandType.Grid,
      content: term.grid,
      grid: term.grid,
      gridParameters: term.gridParameters,
    };
  } else if (term.valueGroup) {
    return {
      kind: PERuleOperandType.ValueGroup,
      content: term.valueGroup,
    };
  } else if (term.field && isList) {
    return {
      kind: PERuleOperandType.Field,
      content: term.field,
    };
  }
}

function parseValueTerm(term, ruleFields, clause, condition) {
  term = cleanUpTerm(term);

  if (!term.listPurpose || term.valueGroup) {
    const body = getValueTermBody(term, ruleFields, clause);
    if (!body) return;

    if (!clause.left) {
      clause.left = body;
      if (condition) {
        clause.condition = condition;
        condition = null;
      }
    } else {
      clause.right = body;
    }
  } else {
    clause.right = {
      kind: PERuleOperandType.List,
      content: term.listValues.map(item =>
        getValueTermBody(item, ruleFields, clause, true),
      ),
      options: getFieldOptions(term.field, ruleFields),
      type: clause.left.type,
    };

    if (
      clause.right.options === PEDateTime &&
      term.listPurpose === PERuleValueListPurpose.Contains
    ) {
      clause.right.options = DATE_TIME_OPTIONS;
      clause.right.dateTimeTab = DATE_TIME_SELECTOR_TABS.DAY;
    }
  }
}

function deleteNullCheckClauses(booleanEquationStructure) {
  // because pe3 microservice datetime fields can be nullable and this can cause exceptions when we convert
  // datetime to date or time that is why we are adding null check clause before each
  // nullable datetime field, this null check clauses should be deleted before we display booleanEquationStructure
  const termsForDelete = [];

  for (let i = 0; i < booleanEquationStructure.length; i++) {
    if (booleanEquationStructure[i].field?.match(/\.HasValue$/)) {
      termsForDelete.unshift(i);
    }
  }

  termsForDelete.forEach(index => booleanEquationStructure.splice(index, 2));
}

export function parseBooleanStructure(booleanEquationStructure, ruleFields) {
  if (!booleanEquationStructure) {
    return [];
  }

  deleteNullCheckClauses(booleanEquationStructure);

  const clauses = [];
  let clause = {};
  let condition = null;
  for (const term of booleanEquationStructure) {
    if (term.eligibilityMatrix) {
      clause.eligibilityMatrix = term.eligibilityMatrix;
    }

    if (term.group) {
      clause.clauses = parseBooleanStructure(term.group, ruleFields);
      if (condition) {
        clause.condition = condition;
        condition = null;
      }
    } else if (term.field) {
      parseFieldTerm(term, ruleFields, clause, condition);
    }
    // If we already set calculation and operator the next value field
    // in boolean equation will be a calculation value
    if (term.value && isCalculationValueMissing(clause.left)) {
      clause.left.calculation.value = term.value;
    } else if (term.value && isCalculationValueMissing(clause.right)) {
      clause.right.calculation.value = term.value;
    } else {
      parseValueTerm(term, ruleFields, clause, condition);
    }

    if (term.operation) {
      if (clause.left && !clause.right) {
        if (isCalculationOperation(term.operation)) {
          clause.allowCalculations = true;
          setCalculationOperation(clause.left, term);
        } else {
          clause.operation = term.operation;
        }
      } else {
        if (isCalculationOperation(term.operation)) {
          clause.allowCalculations = true;
          setCalculationOperation(clause.right, term);
        } else {
          condition = term.operation.toLowerCase();
        }
      }
    }

    clause.listPurpose = term.listPurpose;

    if (
      !clause.operation &&
      !clause.listPurpose &&
      clause.right &&
      clause.right.kind === PERuleOperandType.ValueGroup
    ) {
      clause.listPurpose = PERuleValueListPurpose.Contains;
    }

    if (
      (Array.isArray(clause.clauses) ||
        (clause.left &&
          (clause.operation || clause.listPurpose) &&
          clause.right) ||
        clause.eligibilityMatrix) &&
      !isCalculationValueMissing(clause.right)
    ) {
      // by default all saved clauses are valid
      clause.valid = true;
      clauses.push(clause);
      clause = {};
    }
  }

  return clauses;
}

/** Deserialize result equation structure and fill in data pieces
 * that UI components expect based on the raw data from API
 */
export function parseResultStructure(resultStructure) {
  const items = [...resultStructure];
  const hasCalculation =
    items.length >= 2 && items[0].operation && !isItemLoanAmount(items[1]);
  if (hasCalculation) {
    const calc = {
      operation: items[0].operation,
      ...items[1],
    };
    if (calc.operation && (calc.value || calc.grid)) {
      items[0].calculation = calc;
      items.splice(1, 1);
    }
    if (items.length === 2) {
      delete items[1].operation;
    }
  }
  return items;
}

/** Add calculation operation for the operand.
 * Create calculation if didn't exist
 *
 * @param  {Object} operand left or right side of the clause
 * @param  {Object} term term of the boolean equation
 */
function setCalculationOperation(operand, term) {
  if (!operand.calculation) {
    operand.calculation = {};
  }
  operand.calculation.operation = term.operation;
}

/** If calculation was set, but value for the calculation was not
 *
 * @param  {Object} operand left or right side of the clause
 */
function isCalculationValueMissing(operand) {
  return operand?.calculation && !operand.calculation.value;
}

function isCalculationOperation(operation) {
  return PERuleCalculationOperations.map(o => o.operation).includes(operation);
}

/** the function handles scenario when custom parameter was
 * removed but is still in use.
 * if we have missed parameters we show notification message.
 *
 * @param  {Object} ruleFields instance
 * @param  {Object} rules contains a list of rule instances
 */
export function notifyIfRulesContainsRemovedParams(ruleFields, rules) {
  if (!ruleFields || !rules) return;

  try {
    const missedParams = getMisssedCustomParameters(ruleFields, rules);
    if (!missedParams?.length) return;

    const result = {};
    missedParams.forEach(p => {
      const search = `CustomValue("${p}")`;
      const temp = rules.filter(
        v =>
          v.booleanEquation.includes(search) ||
          (v.resultEquation && v.resultEquation.includes(search)),
      );
      result[p] = temp.map(v => v.name);
    });

    let finalText = '';
    for (const [key, value] of Object.entries(result)) {
      const plural = value?.length > 1;
      const rulesText = `${value?.length ? `: ${value.join(', ')}` : ''}`;
      finalText += `<p>Custom Parameter ${key} has been deleted but is still in use in ${
        plural ? 'rules' : 'a rule'
      }${rulesText}</p>`;
    }

    Vue.notify({
      title: 'Custom parameters have been deleted.',
      text: finalText,
      type: 'warn',
    });
  } catch (error) {
    log(error, LOG_LEVEL.ERROR, ['', 'rule-logic-missing-custom-parameter']);
  }
}

function getMisssedCustomParameters(ruleFields, rules) {
  const re = new RegExp(`CustomValue\\(\\"(.*?)\\"\\)`, 'gi');
  let paramsInRules = [];
  rules.forEach(v => {
    if (v.booleanEquation) {
      const found = [...v.booleanEquation.matchAll(re)].map(v => v[1]);
      if (found?.length) {
        paramsInRules = [...paramsInRules, ...found];
      }
    }
    if (v.resultEquation) {
      const found = [...v.resultEquation.matchAll(re)].map(v => v[1]);
      if (found?.length) {
        paramsInRules = [...paramsInRules, ...found];
      }
    }
  });
  paramsInRules = [...new Set(paramsInRules)]; // remove duplicates
  if (!paramsInRules.length) return;

  const existingParams = ruleFields?.params.map(v => v.name) ?? [];
  return paramsInRules.filter(v => !existingParams.includes(v));
}

export function validResultStructure(ruleType, structure) {
  if (!ruleType) {
    return [];
  } else if (structure && structure.length) {
    if (isEligibilityRule(ruleType)) {
      const resultValue = String(structure[0].value);
      if (
        resultValue !== PEEligibilityValue.Ineligible &&
        resultValue !== PEEligibilityValue.Eligible
      ) {
        structure[0].value = PEEligibilityValue.Eligible;
      }
    } else if (isLockDeskRule(ruleType)) {
      structure[0].value = WORKFLOW_POLICY_DISPLAY.Lock;
    } else {
      if (!structure[0].kind) {
        if (Object.keys(structure[0]).includes('value')) {
          structure[0].kind = PERuleOperandType.Value;
          structure[0].content = structure[0].value;
        } else {
          structure[0].kind = PERuleOperandType.Grid;
          structure[0].content = structure[0].grid;
        }
      }
    }
  } else {
    // Default Result Equation for New Rules
    structure.push({ kind: PERuleOperandType.Value, value: 0 });
    // init content property to make it reactive in vue
    structure[0].content = null;
  }
}

export function validNumericResultStructure(ruleType, structure) {
  validResultStructure(ruleType, structure);
  if (!structure[0]?.content) {
    if (structure.length === 0) {
      // Default Result Equation for New Rules
      structure.push({ kind: PERuleOperandType.Value, value: 0 });
      // init content property to make it reactive in vue
      structure[0].content = null;
    }
    structure[0].type = PEFieldType.decimal;
  }
}
/**
 * Checks if the rule is an adjustment rule
 *
 * @export
 * @param {String} ruleType
 * @returns {Boolean}
 */
export function isAdjustmentRule(ruleType) {
  return (
    ruleType === PERuleCategories.Adjustment ||
    ruleType === PERuleCategories.SRP ||
    ruleType === PERuleCategories.Margin
  );
}

/**
 * Checks if the rule is an mortgage insurance rule
 *
 * @export
 * @param {String} ruleCategory
 * @returns {Boolean}
 */
export function isMortgageInsuranceRule(ruleCategory) {
  return Object.values(PEMIRuleCategories).includes(ruleCategory);
}

/**
 * Checks if the rule is an eligibilty rule
 *
 * @export
 * @param {String} ruleType
 * @returns {Boolean}
 */
export function isEligibilityRule(ruleType) {
  return ruleType === PERuleCategories.Eligibility;
}

/**
 * Checks if the rule is a compliance rule
 *
 * @export
 * @param {String} ruleType
 * @returns {Boolean}
 */
export function isComplianceRule(ruleType) {
  return ruleType === PERuleCategories.Compliance;
}

/**
 * Checks if the rule is a lock desk rule
 *
 * @export
 * @param {String} ruleType
 * @returns {Boolean}
 */
export function isLockDeskRule(ruleType) {
  return ruleType === PERuleCategories.LockDesk;
}

/**
 * Checks if the rule is a fee rule
 *
 * @export
 * @param {String} ruleType
 * @returns {Boolean}
 */
export function isFeeRule(ruleType) {
  return ruleType === PERuleCategories.Fee;
}

/**
 * Checks if the rule is an ARM Margin rule
 *
 * @export
 * @param {String} ruleType
 * @param {String} target
 * @returns {Boolean}
 */
export function isArmMarginRule(ruleType, target) {
  return (
    ruleType === PERuleCategories.Margin && target === RULE_TARGET.ARM_MARGIN
  );
}

/**
 * Checks if the rule is an eligibilty matrix rule
 *
 * @export
 * @param {String} ruleType
 * @returns {Boolean}
 */
export function isEligibilityMatrixRule(ruleType) {
  return ruleType === PERuleCategories.EligibilityMatrix;
}

/**
 * Checks if the rule is a clamp rule
 *
 * @export
 * @param {String} ruleType
 * @returns {Boolean}
 */
export function isClampRule(ruleType) {
  return (
    isClampCategory(ruleType) || isClampCategory(PERuleCategories[ruleType])
  );
}
/**
 * Checks if the rule is a rounding rule
 *
 * @export
 * @param {String} ruleType
 * @returns {Boolean}
 */
export function isRoundingRule(ruleType) {
  return ruleType === PERuleCategories.Rounding;
}

/**
 * Returns a formatted string with spaces after commas
 *
 * @export
 * @param {Object} operand
 * @returns {String}
 */
export function renderedList(operand) {
  if (!operand.type) {
    return;
  }

  if (Array.isArray(operand.content)) {
    return operand.content
      .map(item => {
        let options = item.options ?? operand.options;
        if (!Array.isArray(options)) {
          options = undefined;
        }
        const search = options?.find(
          o => o.id.toString() === item.content.toString(),
        );
        if (search) {
          return search.text;
        } else {
          return item.content;
        }
      })
      .join(', ');
  } else {
    return String(operand.content).replaceAll(',', ', ');
  }
}

export function serializeResultEquationToApiPayload(structure, category) {
  if (category !== PERuleCategories.SRP) {
    return structure;
  }

  const operand = structure[0];
  const calc = operand.calculation;
  if (calc) {
    const item1 = structure[1];
    const isInDollars = isStructureInDollars(structure);
    const alreadyHasCalculationAdded = isInDollars
      ? structure.length === 4
      : structure.length === 2;
    if (!alreadyHasCalculationAdded) {
      structure.splice(1, 0, {
        operation: operand.operation,
        kind: calc.kind,
        value: calc.value,
        grid: calc.grid,
        gridParameters: calc.gridParameters,
      });
      operand.operation = calc.operation;
    } else {
      // update existing "calculation" item (one that was created from structure[0].calculation)
      item1.value = calc.value;
    }
    // always update operation from calc
    operand.operation = calc.operation;
  }

  const last = structure[structure.length - 1];
  if (last) {
    delete last.operation;
  }
  return structure;
}

export function createBooleanEquation(clauses, ruleFields) {
  const booleanEquation = [];
  let lastTerm = null;
  for (const clause of clauses) {
    if (clause.clauses) {
      if (lastTerm && clause.condition) {
        lastTerm.operation = clause.condition;
        booleanEquation.push(lastTerm);
      }
      lastTerm = {};
      lastTerm.group = createBooleanEquation(clause.clauses, ruleFields);
    } else {
      if (clause.eligibilityMatrix) {
        booleanEquation.push(getTermJSON(clause, clause.left));
      } else if (clause.right.kind === PERuleOperandType.ValueGroup) {
        if (lastTerm) {
          if (clause.condition) {
            lastTerm.operation = clause.condition;
          }

          booleanEquation.push(lastTerm);
        }

        lastTerm = {
          ...getTermJSON(clause, clause.left),
          ...getTermJSON(clause, clause.right),
        };
      } else {
        if (lastTerm && clause.condition) {
          lastTerm.operation = clause.condition;
          booleanEquation.push(lastTerm);
        }

        if (!clause.listPurpose) {
          const term = processTerm(clause, clause.left, booleanEquation);
          term.operation = clause.operation;
          booleanEquation.push(term);
        }

        lastTerm = processTerm(clause, clause.right, booleanEquation);
      }
    }
  }
  if (lastTerm) {
    booleanEquation.push(lastTerm);
  }

  canonizeBooleanStructure(booleanEquation, ruleFields);
  return booleanEquation;
}

/** Get JSON term, process calculation applied to the clause
 * if needed
 *
 * @param clause
 * @param operand
 * @param booleanEquation
 */
function processTerm(clause, operand, booleanEquation) {
  const term = getTermJSON(clause, operand);
  if (operand.calculation && term.field) {
    term.operation = operand.calculation.operation;
    booleanEquation.push(term);
    return { value: operand.calculation.value };
  }
  return term;
}

export function getTermJSON(clause, fieldOperand) {
  let termJSON = {};

  if (clause.eligibilityMatrix) {
    termJSON.eligibilityMatrix = clause.eligibilityMatrix;
  } else if (fieldOperand.kind === PERuleOperandType.List) {
    if (
      clause.left.content === COUNTY_FIELD &&
      clause.right.content.every(item =>
        getCountyStatesPattern().test(item.content),
      )
    ) {
      termJSON.field = COMBINED_STATE_COUNTY_FIELD;
    } else {
      termJSON.field = clause.left.content;
    }

    termJSON.listPurpose = clause.listPurpose;
    if (
      [
        PERuleValueListPurpose.Contains,
        PERuleValueListPurpose.NotContains,
      ].includes(clause.listPurpose)
    ) {
      termJSON.listValues = fieldOperand.content.map(item =>
        getTermJSON(clause, item),
      );
    } else {
      termJSON.listValues = fieldOperand.content.map(item => ({
        [item.kind]: item.content,
        // for case if value is grid, otherwise value should be null
        gridParameters: item.gridParameters,
      }));
    }
  } else if (fieldOperand.kind === PERuleOperandType.Grid) {
    termJSON = { ...fieldOperand };
  } else {
    termJSON[fieldOperand.kind] = fieldOperand.content;
    // NOTE: The Micro Service won't accept Strings unless the string includes
    //       escape quote characters (\") within the string. This seems to
    //       to be a requirement because this data ends up being compiled to C#.
    //       The other very special case is when dealing with States, the
    //       Micro Service doesn't have a States enumerations, and as such
    //       it expected State to be a String, but on Django/JavaScript we
    //       need to have an enumeration, so it's not a String in this case... (fun)
    if (
      fieldOperand.kind === PERuleOperandType.Value &&
      ([PEFieldType.string, PEFieldType.county].includes(fieldOperand.type) ||
        clause.left?.content === PEPropertyStateEnumName ||
        clause.left?.content.endsWith(PERuleFieldsChannel) ||
        clause.left?.content.endsWith(PERuleFieldsCountyFipsCode) ||
        (clause.left?.content.startsWith(PERuleFieldsCustomValue) &&
          ![
            PEFieldType.boolean,
            PEFieldType.integer,
            PEFieldType.decimal,
          ].includes(fieldOperand.type)))
    ) {
      termJSON.canonicalValue =
        PERuleStringEscape + fieldOperand.content + PERuleStringEscape;
    }

    if (clause.right.kind === PERuleOperandType.ValueGroup) {
      termJSON.listPurpose = clause.listPurpose;

      if (fieldOperand.content === COUNTY_FIELD) {
        termJSON.field = COMBINED_STATE_COUNTY_FIELD;
      }
    }
  }

  if (clause.right.dateTimeTab || clause.right.content[0]?.dateTimeTab) {
    // need to save dateTimeTab information for makeDateTimeAdjustments
    termJSON.dateTimeTab =
      clause.right.dateTimeTab || clause.right.content[0]?.dateTimeTab;
  }
  return termJSON;
}

export function canonizeBooleanStructure(booleanStructure, ruleFields) {
  makeDateTimeAdjustments(booleanStructure);

  for (const term of booleanStructure) {
    if (term.field && !term.valueGroup) {
      canonizeFieldTerm(term, ruleFields);
    }
    if (term.value && term.canonicalValue) {
      term.value = term.canonicalValue;
      delete term.canonicalValue;
    }
    if (term.group) {
      canonizeBooleanStructure(term.group, ruleFields);
    }
    if (term.listValues?.length) {
      canonizeBooleanStructure(term.listValues, ruleFields);
    }
  }
}

function makeDateTimeAdjustments(booleanStructure) {
  const result = [];
  for (let i = 0; i < booleanStructure.length; i++) {
    const term = booleanStructure[i];
    if (
      [
        DATE_TIME_SELECTOR_TABS.TIME,
        DATE_TIME_SELECTOR_TABS.DATE,
        DATE_TIME_SELECTOR_TABS.DAY,
      ].includes(term.dateTimeTab)
    ) {
      if (
        NULLABLE_DATE_TIME_FIELDS.includes(term.field) &&
        !JSON.stringify(result).includes(term.field + '.HasValue')
      ) {
        const nullCheckClause = clone(NULL_CHECK_CLAUSE);
        nullCheckClause[0].field = nullCheckClause[0].field.replace(
          '#',
          term.field,
        );
        result.splice(0, 0, ...nullCheckClause);
      }

      if (term.field) {
        term.field = getDateTimeField(term.dateTimeTab, term.field);
      }
      if (term.value) {
        term.value = getDateTimeValue(term.dateTimeTab, term.value);
      }
      if (term.listValues) {
        term.listValues.forEach(
          listValue =>
            (listValue.value = getDateTimeValue(
              term.dateTimeTab,
              listValue.value,
            )),
        );
      }
    }
    result.push(term);
  }

  booleanStructure.splice(0, booleanStructure.length, ...result);
}

function getDateTimeField(dateTimeTab, field) {
  if (field.match(TIME_DATE_ONLY_REGEX)) return field;

  const valueSuffix = NULLABLE_DATE_TIME_FIELDS.includes(field) ? '.Value' : '';

  switch (dateTimeTab) {
    case DATE_TIME_SELECTOR_TABS.TIME:
      return `TimeOnly.FromDateTime(#${valueSuffix})`.replace('#', field);
    case DATE_TIME_SELECTOR_TABS.DATE:
      return `DateOnly.FromDateTime(#${valueSuffix})`.replace('#', field);
    case DATE_TIME_SELECTOR_TABS.DAY:
      return `DateOnly.FromDateTime(#${valueSuffix}).DayOfWeek`.replace(
        '#',
        field,
      );
    default:
      return field;
  }
}

function getDateTimeValue(dateTimeTab, value) {
  if (value.toString().match(TIME_DATE_ONLY_REGEX)) return value;

  switch (dateTimeTab) {
    case DATE_TIME_SELECTOR_TABS.DATE:
      return `DateOnly.Parse("${moment(value).utc().format(DATE_ISO_FORMAT)}")`;
    case DATE_TIME_SELECTOR_TABS.TIME:
      return `TimeOnly.Parse("${moment(value).utc().format(TIME_ISO_FORMAT)}")`;
    default:
      return value;
  }
}

function canonizeFieldTerm(term, ruleFields) {
  const copyTerm = cleanUpTerm(term);

  if (
    copyTerm.field !== PEPropertyStateEnumName &&
    copyTerm.field !== PERuleFieldsChannel &&
    !copyTerm.field.endsWith(PERuleFieldsCountyFipsCode) &&
    !copyTerm.field.startsWith(PERuleFieldsCustomValue) &&
    typeForField(copyTerm, ruleFields) === PEFieldType.list
  ) {
    // This is necessary for the time being because the Micro Service
    // currently doesn't have a way to accept an Integer for an
    // operand of Enum type, and sending a named Enumeration as
    // required by the Micro Service involves a fair amount of
    // work that is beyond the scope of the task.
    // The exceptions are 'Property.State', 'CustomValue' which are not
    // an enumeration in the Micro Service.
    term.field = PEEnumCast + term.field;
  }

  // hack to add customValue type for microservice purposes
  if (term.field.startsWith(PERuleFieldsCustomValue)) {
    term.field = addCustomValueType(term.field, ruleFields);
  }
}

export function addCustomValueType(fieldName, ruleFields) {
  const { propertyName } = ruleFields.parseField(fieldName);
  const customValueType = ruleFields.getCustomField(propertyName);

  let peClass = null;
  if (typeof customValueType === 'object') {
    peClass =
      CUSTOM_PARAMETER_TYPES_CONFIG[CUSTOM_PARAMETER_TYPES.ENUM].peClass;
  } else {
    peClass = Object.values(CUSTOM_PARAMETER_TYPES_CONFIG).find(
      config => config.type === customValueType,
    ).peClass;
  }

  return `${PERuleFieldsCustomValue}${peClass ? '<' + peClass + '>' : ''}(${
    PERuleStringEscape + propertyName + PERuleStringEscape
  })`;
}

/**
 * get inversed values for min/max clamp values if submitting with valid clamp category
 *
 * @export
 * @param { maxValue?: Number, minValue?: Number } clampValues
 * @param { String } clampCategory
 * @returns { maxValue, minValue }
 */
export function getInversedClampValues(clampValues, clampCategory) {
  let invertedClampValues = {};

  // remove empty strings and set to undefined
  let minValue = clampValues.minValue !== '' ? clampValues.minValue : undefined;
  let maxValue = clampValues.maxValue !== '' ? clampValues.maxValue : undefined;

  // can have positives, negatives, zeros, or undefined
  minValue = Number(minValue);
  maxValue = Number(maxValue);

  // set NaN to empty string
  if (isNaN(minValue)) {
    minValue = '';
  }
  if (isNaN(maxValue)) {
    maxValue = '';
  }

  if (
    [PEClampType.Adjustment, PEClampType.Margin, PEClampType.SRP].includes(
      clampCategory,
    )
  ) {
    if (maxValue || maxValue === 0) {
      invertedClampValues.minValue = maxValue * -1;
    } else {
      invertedClampValues.minValue = '';
    }

    if (minValue || minValue === 0) {
      invertedClampValues.maxValue = minValue * -1;
    } else {
      invertedClampValues.maxValue = '';
    }
  } else {
    invertedClampValues = {
      maxValue,
      minValue,
    };
  }

  return invertedClampValues;
}

/**
 * Updates resultEquationStructure based on eligibilityType
 *
 * @export
 * @param {String} eligibilityType
 * @param {String} ruleType
 * @param {Object} resultStructure
 */
export function updateEligibilityResultEquation(
  eligibilityType,
  ruleType,
  resultStructure,
) {
  if (!isEligibilityRule(ruleType) && !isEligibilityMatrixRule(ruleType)) {
    throw `Use updateResultEquation for non-eligibility rules, ${ruleType}`;
  }

  resultStructure.length = 0;
  const result = {};

  if (!isEligibilityMatrixRule(ruleType)) {
    result.value =
      String(eligibilityType) === String(PEEligibilityType.Eligible)
        ? PEEligibilityValue.Eligible
        : PEEligibilityValue.Ineligible;
    resultStructure.push(result);
  }
  // for eligibility matrix rule, resultEquationStructure will be generated before save
}

/**
 * Updates resultEquationStructure for all rules except eligibility rule
 *
 * @export
 * @param {String} newResult
 * @param {String} ruleType
 * @param {Object} resultStructure
 */
export function updateResultEquation(newResult, ruleType, resultStructure) {
  let result = {};

  result.kind = newResult.kind;
  if (isEligibilityRule(ruleType) || isEligibilityMatrixRule(ruleType)) {
    throw 'Use updateEligibilityResultEquation for eligibility rules';
  } else if (isLockDeskRule(ruleType)) {
    result.value = WORKFLOW_POLICY_DISPLAY.Lock;
  } else {
    if (newResult.kind === PERuleOperandType.Value) {
      result.value = newResult.newValue;
    } else if (newResult.kind === PERuleOperandType.Grid) {
      if (newResult.newGrid) {
        result = newResult.newGrid;
      } else {
        result.grid = null;
      }
    }
  }

  if (resultStructure.length > 0) {
    // if result adjustment for example in dollars,
    // we can't just cleanup all resultStructure,
    // need only update the first element of array
    delete resultStructure[0].value;
    delete resultStructure[0].grid;
    delete resultStructure[0].gridParameters;

    Object.assign(resultStructure[0], result);
  } else {
    resultStructure.push(result);
  }
}

export function getNormalizedMatrix(matrixCopy) {
  // main purpose of the function is to convert
  // multiple rule rows (rule group) to a
  // single rule row with content as array of values.
  // other rules leave without changes.

  if (!matrixCopy || !matrixCopy.length) return [];
  const convertMatrix = [];
  for (const rule of matrixCopy) {
    const newRule = [];
    for (const item of rule) {
      if (item.clauses) {
        const row = item.clauses[0];
        const content = [];
        item.clauses.forEach(v => content.push(v.right.content));
        row.right.content = content;
        if (item.condition) {
          row.condition = item.condition;
        }
        newRule.push(row);
      } else {
        newRule.push(item);
      }
    }
    convertMatrix.push(newRule);
  }

  return convertMatrix;
}

export function getGroupItem() {
  const newClause = {
    condition: PERuleBooleanOperator.And,
    clauses: [],
    valid: true,
  };
  return newClause;
}

export function getEmptyRuleClause() {
  return {
    left: {
      kind: PERuleOperandType.Field,
      content: null,
    },
    right: {},
    valid: false,
  };
}

export function convertResultStructureToDollars(
  resultStructure,
  setTargetCallback,
) {
  if (resultStructure.length >= 1) {
    // adding specific configuration if units is dollars
    resultStructure[0].operation = PERuleOperations.Divide.operation;
    resultStructure.push({
      field: 'Product.LoanAmount',
      operation: PERuleOperations.Multiply.operation,
    });
    resultStructure.push({
      value: 100,
    });
  }
  setTargetCallback(RULE_TARGET.PRICE);
}

const PERCENT_VALUE = '100.0';

function isItemLoanAmount(resultStructureItem) {
  return resultStructureItem.field === 'Product.LoanAmount';
}

function isItem100Percent(resultStructureItem) {
  return [PERCENT_VALUE, '100', 100].includes(resultStructureItem.value);
}

export function isStructureInDollars(resultStructure) {
  const len = resultStructure.length;
  if (len >= 2) {
    const last = resultStructure[len - 1];
    const preLast = resultStructure[len - 2];

    const lastIs100Percent = isItem100Percent(last);
    const preLastIsLoanAmountMultiply =
      isItemLoanAmount(preLast) &&
      preLast.operation === PERuleOperations.Multiply.operation;
    return lastIs100Percent && preLastIsLoanAmountMultiply;
  }
  return false;
}

export function isStructureInBasisPoints(resultStructure) {
  return !isStructureInDollars(resultStructure);
}

export function convertFeeResultStructureToDollars(
  resultStructure,
  setTargetCallback,
) {
  if (isStructureInBasisPoints(resultStructure)) {
    // for fees dollar amounts are just a number with no additional rules
    resultStructure.splice(1, resultStructure.length - 1);
    delete resultStructure[0].operation;
  }
  setTargetCallback(RULE_TARGET.PRICE);
}

function convertFeeResultStructureToBasisPoints(resultStructure) {
  if (resultStructure.length === 1) {
    // for fees we must translate from percent to dollar amount
    resultStructure[0].operation = PERuleOperations.Divide.operation;
    resultStructure.push({
      value: PERCENT_VALUE,
      operation: PERuleOperations.Multiply.operation,
    });
    resultStructure.push({
      field: 'Loan.Amount',
    });
  }
}

export function convertResultStructureToBasisPoints(resultStructure) {
  if (resultStructure.length > 1) {
    // basis points is just number with no additional rules
    resultStructure.splice(1, resultStructure.length - 1);
    delete resultStructure[0].operation;
  }
}

export function convertResultStructureToPriceBasisPoints(
  resultStructure,
  setTargetCallback,
) {
  convertResultStructureToBasisPoints(resultStructure);
  setTargetCallback(RULE_TARGET.PRICE);
}

export function convertFeeResultStructureToPriceBasisPoints(
  resultStructure,
  setTargetCallback,
) {
  convertFeeResultStructureToBasisPoints(resultStructure);
  setTargetCallback(RULE_TARGET.PRICE);
}

export function convertResultStructureToRateBasisPoints(
  resultStructure,
  setTargetCallback,
) {
  convertResultStructureToBasisPoints(resultStructure);
  setTargetCallback(RULE_TARGET.RATE);
}

export function convertResultStructureToPercent(
  resultStructure,
  setTargetCallback,
) {
  convertResultStructureToBasisPoints(resultStructure);
  setTargetCallback(RULE_TARGET.ARM_MARGIN);
}

export function getEligibilityResult(resultStructure) {
  if (!resultStructure.length) {
    return undefined;
  }
  if (resultStructure[0].eligibilityMatrix) {
    return PEEligibilityType.Matrix;
  } else {
    return String(resultStructure[0].value) ===
      String(PEEligibilityValue.Eligible)
      ? PEEligibilityType.Eligible
      : PEEligibilityType.Ineligible;
  }
}

export function getCountyStatesPattern() {
  return new RegExp(
    '^(' +
      Object.values(STATES)
        .map(v => v.abbrev)
        .join('|') +
      ')-',
  );
}

export function getDateTimeDisplayValue(dateTimeTab, value) {
  if (!dateTimeTab) {
    dateTimeTab = DATE_TIME_SELECTOR_TABS.DATETIME;
  }

  switch (dateTimeTab) {
    case DATE_TIME_SELECTOR_TABS.DATE:
      return moment(value).utc().format(DATE_DISPLAY_FORMAT);
    case DATE_TIME_SELECTOR_TABS.TIME:
      return moment(value).utc().format(TIME_DISPLAY_FORMAT);
    case DATE_TIME_SELECTOR_TABS.DATETIME:
    default:
      return moment(value).utc().format(DATETIME_DISPLAY_FORMAT);
    case DATE_TIME_SELECTOR_TABS.DAY:
      return Object.keys(DATE_TIME_DAY_OF_WEEK).find(
        key => DATE_TIME_DAY_OF_WEEK[key] === value,
      );
  }
}

export const exportForTest = {
  getDateTimeValue,
};

export default {
  MIN_MAX_DOLLARS_TEXT,
  addCustomValueType,
  canonizeBooleanStructure,
  convertResultStructureToDollars,
  convertResultStructureToPriceBasisPoints,
  convertResultStructureToRateBasisPoints,
  convertFeeResultStructureToPriceBasisPoints,
  convertFeeResultStructureToDollars,
  getCountyStatesPattern,
  createBooleanEquation,
  createRightSide,
  getEligibilityResult,
  getEmptyRuleClause,
  getFieldOptions,
  getGroupItem,
  getInversedClampValues,
  getNormalizedMatrix,
  getTermJSON,
  getType,
  getDateTimeDisplayValue,
  isAdjustmentRule,
  isClampRule,
  isRoundingRule,
  isEligibilityMatrixRule,
  isEligibilityRule,
  isLockDeskRule,
  isFeeRule,
  makeDateObjectFromDateString,
  makeDateObjectFromTimeString,
  parseBooleanStructure,
  renderedList,
  typeForField,
  updateResultEquation,
  updateEligibilityResultEquation,
  validResultStructure,
  validNumericResultStructure,
};
