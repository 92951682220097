/* eslint-disable import/no-named-as-default-member */
// mixpanels module definition and types don't match so we disable this rule
import { ref } from 'vue';

import { log } from '@shared/utils/logging';
import mixpanel from 'mixpanel-browser';

const mixPanelPlugin = {
  install: async () => {
    if (!window.MIXPANEL_TOKEN) {
      console.info('No Mixpanel token found');
      return;
    }

    mixpanel.init(window.MIXPANEL_TOKEN);
    trackingEnabled.value = true;
  },
};

const orgId = ref(null);
const orgName = ref(null);
const tickerSymbol = ref(null);
const trackingEnabled = ref(false);

export function identifyUser(userId) {
  if (!trackingEnabled.value) return;

  mixpanel.identify(`${userId}`);
}

export function updateUserProperties(newOrgId, newOrgName, newTickerSymbol) {
  if (!trackingEnabled.value) return;
  orgId.value = newOrgId;
  orgName.value = newOrgName;
  tickerSymbol.value = newTickerSymbol;
}

export function trackEvent(event, properties = {}) {
  if (!trackingEnabled.value) return;

  const allProperties = {
    org_id: orgId.value,
    org_name: orgName.value,
    org_ticker: tickerSymbol.value,
    ...properties,
  };

  mixpanel.track(event, allProperties);
}

export function trackPageView(pageName, properties = {}) {
  if (!trackingEnabled.value) return;

  const allProperties = {
    org_id: orgId.value,
    org_name: orgName.value,
    org_ticker: tickerSymbol.value,
    ...properties,
  };

  mixpanel.track_pageview({
    page: pageName,
    ...allProperties,
  });
}

export { mixPanelPlugin };
