import {
  getEntities,
  getUniqueEntityCopyName,
} from '@pe/services/configurations.js';
import {
  LOCK_DESK_WORKFLOW_FEE_TYPES as FEE_TYPES,
  LOCK_DESK_WORKFLOW_POLICY_TYPES as WORKFLOW_POLICY_TYPES,
  PELockDeskWorkflowCalculationLockPeriodType as WORKFLOW_LOCK_PERIOD_TYPE,
  PELockDeskWorkflowBuySidePriceCalculationRuleType as BUY_SIDE_PRICE_CALCULATION_RULE_TYPE,
} from '@shared/constants';
import api from '@shared/services/api.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import { isSuccessResponse } from '@shared/utils/index.js';
import {
  ADD_LEVEL_TO_REVIEWAL_SEQUENCE,
  CLEAR_CURRENT_POLICY,
  CLEAR_POLICY_DETAILS,
  CLEAR_REVIEWAL_SEQUENCE,
  SET_ACTIVE_WORKFLOW_POLICIES,
  SET_ALL_POLICIES_LIST,
  SET_PRICE_EXCEPTION_FILE_UPLOAD_SETTINGS,
  SET_APPROVAL_TYPE_IN_REVIEWAL_SEQUENCE,
  SET_APPROVER_GROUP_IN_REVIEWAL_SEQUENCE,
  SET_COMMENT_REQUIRED,
  SET_CURRENT_PRODUCT_POLICY_BY_TYPE,
  SET_CUSTOM_POLICIES,
  SET_DEFAULT_POLICIES_LIST,
  SET_DEFAULT_POLICIES_OBJECT,
  SET_DEFAULT_POLICY_PRODUCTS,
  SET_EXCEPTION_CEILING_IN_REVIEWAL_SEQUENCE,
  SET_IS_ALL_POLICIES_LOADING,
  SET_IS_CUSTOM_POLICIES_LOADING,
  SET_IS_DEFAULT_POLICIES_LOADING,
  SET_IS_LOCK_DESK_SETTINGS_LOADING,
  SET_IS_NEW_POLICY,
  SET_IS_POLICY_LOADING,
  SET_IS_POLICY_OPTIONS_LOADING,
  SET_IS_POLICY_PAGE_LOADING,
  SET_IS_POLICY_SAVING,
  SET_LOCK_DESK_SETTINGS,
  SET_NUMBER_OF_APPROVERS_IN_REVIEWAL_SEQUENCE,
  SET_ORDER_IN_REVIEWAL_SEQUENCE,
  SET_POLICY,
  SET_POLICY_AUTO_APPROVAL_LEVEL,
  SET_POLICY_DOCUMENT_REQUIRED_THRESHOLD,
  SET_POLICY_FEE,
  SET_POLICY_FEE_SET,
  SET_POLICY_FEE_TYPE,
  SET_POLICY_IS_AUTO_APPROVAL_ENABLED,
  SET_POLICY_MAX_DAYS_EXTENDED,
  SET_POLICY_MAX_EXCEPTIONS,
  SET_POLICY_MAX_WORKFLOW_USAGE,
  SET_POLICY_MIN_DAYS_EXTENDED,
  SET_POLICY_NAME,
  SET_POLICY_NOTES,
  SET_POLICY_PRODUCTS,
  SET_POLICY_SEQUENCES,
  SET_POLICY_STATUS,
  SET_POLICY_TYPE,
  SET_POLICY_TYPE_FILTER,
  SET_POLICY_WORST_CASE_PRICING,
  SET_PRODUCT_DETAILS_POLICY_OPTIONS,
  SET_REQUESTOR_GROUP_IN_REVIEWAL_SEQUENCE,
  SET_SEARCH_TEXT,
  SET_SEQUENCE,
  UPDATE_LEVEL_IN_REVIEWAL_SEQUENCE,
  SET_DENY_REASONS,
  SET_IS_REASON_REQUIRED,
  SET_IS_REASON_ENABLED,
  SET_RELOCK_DAYS_EXPIRED_ENABLED,
  SET_RELOCK_DAYS_LOCKED_ENABLED,
  SET_RELOCK_DAYS_EXPIRED_OPERATOR,
  SET_RELOCK_DAYS_LOCKED_OPERATOR,
} from './mutationTypes.js';
import { fetchPolicies } from '@pe/functions/helpers';

async function fetchLegacyFloatDownPolicy() {
  try {
    const permissions = await api.get('/pe/api/workflow-permissions/');
    const { has_float_down_enabled } = permissions;
    if (has_float_down_enabled) {
      const floatDownPolicy = await api.get(
        '/pe/api/lender-float-down-policy/',
      );
      return {
        type: WORKFLOW_POLICY_TYPES.FLOAT_DOWN,
        isWorkflowEnabled: true,
        isAutoApprovalEnabled: permissions.has_float_down_autoapproval_enabled,
        ...floatDownPolicy,
      };
    }
  } catch (error) {
    onErrorHandler(error, 'pe-fetch-legacy-floatdown-policy');
  }
}
async function patchPolicy(requestPayload, productIds, configId, policyId) {
  delete requestPayload.created_name;
  delete requestPayload.isDefaultPolicy;
  delete requestPayload.productTypeCounts;

  requestPayload.productIds = productIds;

  await api.patch(
    `/pe/api/configurations/${configId}/lockdeskworkflowpolicies`,
    {
      entity_id: policyId,
      ...requestPayload,
    },
  );
}

const emptySequence = {
  levels: [
    {
      approvalGroup: '',
      order: '',
      exceptionLimitCeiling: '',
      numApprovals: '',
    },
  ],
  approvalType: '',
  requestorGroup: '',
};

const emptyPolicyBase = {
  name: '',
  isDefaultPolicy: null,
  productIds: null,
  type: null,
  isEnabled: false,
  isWorkflowEnabled: null,
  isAutoApprovalEnabled: false,
  autoApprovalLevel: 'NoPriceChange',
  maxWorkflowUsage: null,
  feeType: null,
  fee: [],
  feeSet: [],
  feeTypeIsSequence: null,
  commentRequired: 0,
  notes: '',
  createdBy: null,
  createdOn: null,
  exceptionLimit: null,
};

const emptyPolicyException = {
  ...emptyPolicyBase,
  type: WORKFLOW_POLICY_TYPES.PRICE_EXCEPTION,
  denyReasons: [],
  isDenyReasonRequired: false,
  isDenyReasonEnabled: false,
  documentationRequiredThreshold: null,
  sequences: [],
};

const emptyPolicyExtension = {
  ...emptyPolicyBase,
  type: WORKFLOW_POLICY_TYPES.EXTENSION,
  maxDaysExtended: null,
  minDaysExtended: 1,
};

const emptyPolicyRelock = {
  ...emptyPolicyBase,
  type: WORKFLOW_POLICY_TYPES.RELOCK,
  relockWorstCasePricingThreshold: null,
  relockDaysExpiredEnabled: false,
  relockDaysExpiredOperator: null,
  relockDaysLockedEnabled: false,
  relockDaysLockedOperator: null,
};

const emptyPolicyReprice = {
  ...emptyPolicyBase,
  type: WORKFLOW_POLICY_TYPES.REPRICE,
  isAutoRepriceTriggered: false,
};

const emptyPolicyRenegotiation = {
  ...emptyPolicyBase,
  type: WORKFLOW_POLICY_TYPES.RENEGOTIATION,
  isAllowableLockPeriodsEnabled: false,
  allowableLockPeriods: [],
  isAllowableLosStatusesEnabled: false,
  allowableLOSStatuses: [],
  calculationLockPeriodMethod: WORKFLOW_LOCK_PERIOD_TYPE.SAME_AS_INITIAL_LOCK,
  calculationLockPeriod: null,
  isCurrentMarketPriceAdjustmentEnabled: false,
  currentMarketPriceAdjustment: null,
  isMinAndMaxRateImprovementEnabled: false,
  minRateImprovement: null,
  maxRateImprovement: null,
  isUpdateLockDateToDateOfRequest: false,
  buySidePriceCalculationRule:
    BUY_SIDE_PRICE_CALCULATION_RULE_TYPE.PRICE_CAN_NOT_EXCEED_CURRENT_BUY_PRICE,
  isFeeToBuyPriceEnabled: false,
  feeToBuyPrice: null,
  feeToBuyPriceUnits: 'PERCENT',
  isRateAdjustmentEnabled: false,
  rateAdjustment: null,
  isExtendDaysToExpireEnabled: false,
  extendDaysToExpire: null,
  isRemoveDaysToExpireEnabled: false,
  removeDaysToExpire: null,
};

const getEmptyPolicy = type => {
  switch (type) {
    case WORKFLOW_POLICY_TYPES.PRICE_EXCEPTION:
      return emptyPolicyException;
    case WORKFLOW_POLICY_TYPES.EXTENSION:
      return emptyPolicyExtension;
    case WORKFLOW_POLICY_TYPES.RELOCK:
      return emptyPolicyRelock;
    case WORKFLOW_POLICY_TYPES.REPRICE:
      return emptyPolicyReprice;
    case WORKFLOW_POLICY_TYPES.RENEGOTIATION:
      return emptyPolicyRenegotiation;
    default:
      return emptyPolicyBase;
  }
};

const initialState = () => {
  return {
    reviewalSequence: { ...emptySequence },
    activeWorkflowPolicies: null,
    allPolicies: [],
    currentPolicy: { ...emptyPolicyBase },
    customPolicies: [],
    defaultPolicies: [],
    defaultPoliciesMap: {},
    defaultPolicyProducts: [],
    isAllPoliciesLoading: true,
    isCustomPoliciesLoading: true,
    isDefaultPoliciesLoading: true,
    isLockDeskSettingsLoading: true,
    isNewPolicy: false,
    isPolicyLoading: true,
    isPolicyOptionsLoading: true,
    isPolicyPageLoading: true,
    isPolicySaving: false,
    policyTypeFilter: 'all',
    peFileUploadSettings: {},
    productDetailPolicyOptions: {
      [WORKFLOW_POLICY_TYPES.CANCEL]: [],
      [WORKFLOW_POLICY_TYPES.EXTENSION]: [],
      [WORKFLOW_POLICY_TYPES.LOCK]: [],
      [WORKFLOW_POLICY_TYPES.PRICE_EXCEPTION]: [],
      [WORKFLOW_POLICY_TYPES.RELOCK]: [],
      [WORKFLOW_POLICY_TYPES.REPRICE]: [],
      [WORKFLOW_POLICY_TYPES.RENEGOTIATION]: [],
    },
    currentProductPoliciesSelected: {
      [WORKFLOW_POLICY_TYPES.CANCEL]: null,
      [WORKFLOW_POLICY_TYPES.EXTENSION]: null,
      [WORKFLOW_POLICY_TYPES.LOCK]: null,
      [WORKFLOW_POLICY_TYPES.PRICE_EXCEPTION]: null,
      [WORKFLOW_POLICY_TYPES.RELOCK]: null,
      [WORKFLOW_POLICY_TYPES.REPRICE]: null,
      [WORKFLOW_POLICY_TYPES.RENEGOTIATION]: null,
    },
    lockDeskSettings: {
      has_cancellation_enabled: false,
      has_extensions_enabled: false,
      has_product_change_enabled: false,
      has_float_downs_enabled: false,
      has_float_request_enabled: false,
      has_lock_exceptions_enabled: false,
      has_lock_requests_enabled: false,
      has_relock_enabled: false,
      has_reprice_enabled: false,
    },
    searchText: '',
  };
};

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    reviewalSequence: state => state.reviewalSequence,
    currentPolicyPayload(state) {
      const currentPolicyCopy = { ...state.currentPolicy };
      currentPolicyCopy.sequences = currentPolicyCopy.sequences?.map(
        sequence => sequence.id,
      );
      return currentPolicyCopy;
    },
    allPolicies: state => state.allPolicies,
    appliedProducts: (_, getters, ___, rootGetters) => {
      const configuration = rootGetters['pricingVersionDetails/configuration'];
      return configuration?.products
        ?.filter(({ id }) => getters.currentPolicyProductIds.includes(id))
        .map(product => ({
          ...product,
          status: product.isActive ? 'Active' : 'Inactive',
        }));
    },
    currentPolicy: state => state.currentPolicy,
    peFileUploadSettings: state => state.peFileUploadSettings,
    currentPolicyProductIds: state => state.currentPolicy?.productIds || [],
    currentProductPoliciesSelected: state =>
      state.currentProductPoliciesSelected,
    currentProductPolicyCancel: state =>
      state.currentProductPoliciesSelected[WORKFLOW_POLICY_TYPES.CANCEL],
    currentProductPolicyExtension: state =>
      state.currentProductPoliciesSelected[WORKFLOW_POLICY_TYPES.EXTENSION],
    currentProductPolicyLock: state =>
      state.currentProductPoliciesSelected[WORKFLOW_POLICY_TYPES.LOCK],
    currentProductPolicyPriceException: state =>
      state.currentProductPoliciesSelected[
        WORKFLOW_POLICY_TYPES.PRICE_EXCEPTION
      ],
    currentProductPolicyRelock: state =>
      state.currentProductPoliciesSelected[WORKFLOW_POLICY_TYPES.RELOCK],
    currentProductPolicyReprice: state =>
      state.currentProductPoliciesSelected[WORKFLOW_POLICY_TYPES.REPRICE],
    customPolicies: state => state.customPolicies,
    defaultPolicies: state => state.defaultPolicies,
    defaultPoliciesMap: state => state.defaultPoliciesMap,
    defaultPolicyProducts: state => state.defaultPolicyProducts,
    isAllPoliciesLoading: state => state.isAllPoliciesLoading,
    isCustomPoliciesLoading: state => state.isCustomPoliciesLoading,
    isDefaultPoliciesLoading: state => state.isDefaultPoliciesLoading,
    isDefaultPolicy: state => state.currentPolicy.isDefaultPolicy,
    isLockDeskSettingsLoading: state => state.isLockDeskSettingsLoading,
    isNewPolicy: state => state.isNewPolicy,
    isPolicyLoading: state => state.isPolicyLoading,
    isPolicyOptionsLoading: state => state.isPolicyOptionsLoading,
    isPolicyPageLoading: state => state.isPolicyPageLoading,
    isPolicySaving: state => state.isPolicySaving,
    lockDeskSettings: state => state.lockDeskSettings,
    commentRequired: state => state.currentPolicy.commentRequired,
    policyFee: state => state.currentPolicy.fee,
    policyFeeSet: state => state.currentPolicy.feeSet,
    policyFeeType: state => state.currentPolicy.feeType,
    policyIsAutoApprovalEnabled: state =>
      state.currentPolicy.isAutoApprovalEnabled,
    policyAutoApprovalLevel: state => state.currentPolicy.autoApprovalLevel,
    policyMaxDaysExtended: state => state.currentPolicy.maxDaysExtended,
    policyMaxExceptions: state => state.currentPolicy.exceptionLimit,
    policyMaxWorkflowUsage: state => state.currentPolicy.maxWorkflowUsage,
    policyMinDaysExtended: state => state.currentPolicy.minDaysExtended,
    policyName: state => state.currentPolicy.name,
    policyNotes: state => state.currentPolicy.notes,
    policyStatus: state => state.currentPolicy.isEnabled,
    policyType: state => state.currentPolicy.type,
    policyTypeFilter: state => state.policyTypeFilter,
    policyWorstCasePricing: state =>
      state.currentPolicy.relockWorstCasePricingThreshold,
    policyDocumentationRequiredThreshold: state =>
      state.currentPolicy.documentationRequiredThreshold,
    productDetailPolicyOptions: state => state.productDetailPolicyOptions,
    searchText: state => state.searchText,
    getWorkflow: state => workflowType =>
      state.activeWorkflowPolicies?.find(
        policy => policy.type === workflowType,
      ) || {},
    lockPolicy: (_, getters) => getters.getWorkflow(WORKFLOW_POLICY_TYPES.LOCK),
    repricePolicy: (_, getters) =>
      getters.getWorkflow(WORKFLOW_POLICY_TYPES.REPRICE),
    cancelPolicy: (_, getters) =>
      getters.getWorkflow(WORKFLOW_POLICY_TYPES.CANCEL),
    extensionPolicy: (_, getters) => {
      const policy = getters.getWorkflow(WORKFLOW_POLICY_TYPES.EXTENSION);
      return {
        ...policy,
        min_lock_extension_days: policy.minDaysExtended,
        max_lock_extensions: policy.maxWorkflowUsage,
        max_lock_extension_days: policy.maxDaysExtended,
      };
    },
    priceExceptionPolicy: (_, getters) => {
      const policy = getters.getWorkflow(WORKFLOW_POLICY_TYPES.PRICE_EXCEPTION);
      return {
        ...policy,
        max_requests: policy.maxWorkflowUsage,
        limit: policy.exceptionLimit,
      };
    },
    relockPolicy: (_, getters) => {
      const policy = getters.getWorkflow(WORKFLOW_POLICY_TYPES.RELOCK);
      const fees =
        policy.feeSet?.map(([period, ...adjustments]) => ({
          lock_period: period,
          fees: adjustments,
        })) || [];
      return {
        ...policy,
        max_relocks: policy.maxWorkflowUsage,
        max_day_threshold: policy.relockWorstCasePricingThreshold,
        fees: fees,
      };
    },
    floatDownPolicy: (_, getters) => {
      return getters.getWorkflow(WORKFLOW_POLICY_TYPES.FLOAT_DOWN);
    },
    floatRequestPolicy: (_, getters) => {
      return getters.getWorkflow(WORKFLOW_POLICY_TYPES.FLOAT_REQUEST);
    },
    productChangePolicy: (_, getters) => {
      return getters.getWorkflow(WORKFLOW_POLICY_TYPES.PRODUCT_CHANGE);
    },
  },
  mutations: {
    [CLEAR_CURRENT_POLICY](state) {
      Object.assign(state.currentPolicy, emptyPolicyBase);
      state.isNewPolicy = false;
    },
    [SET_PRICE_EXCEPTION_FILE_UPLOAD_SETTINGS](state, payload) {
      state.peFileUploadSettings = payload;
    },
    [SET_ALL_POLICIES_LIST](state, payload) {
      state.allPolicies = payload;
    },
    [SET_CURRENT_PRODUCT_POLICY_BY_TYPE](state, payload) {
      state.currentProductPoliciesSelected[payload.type] = payload.value;
    },
    [SET_CUSTOM_POLICIES](state, payload) {
      state.customPolicies = payload;
    },
    [SET_DEFAULT_POLICIES_LIST](state, payload) {
      state.defaultPolicies = payload;
    },
    [SET_DEFAULT_POLICIES_OBJECT](state, payload) {
      state.defaultPoliciesMap = payload;
    },
    [SET_DEFAULT_POLICY_PRODUCTS](state, payload) {
      state.defaultPolicyProducts = payload;
    },
    [SET_POLICY_FEE](state, payload) {
      state.currentPolicy.fee = payload;
    },
    [SET_POLICY_FEE_SET](state, payload) {
      state.currentPolicy.feeSet = payload;
    },
    [SET_POLICY_FEE_TYPE](state, payload) {
      state.currentPolicy.feeType = payload;
    },
    [SET_POLICY_IS_AUTO_APPROVAL_ENABLED](state, payload) {
      state.currentPolicy.isAutoApprovalEnabled = payload;
    },
    [SET_POLICY_AUTO_APPROVAL_LEVEL](state, payload) {
      state.currentPolicy.autoApprovalLevel = payload;
    },
    [SET_POLICY_MAX_DAYS_EXTENDED](state, payload) {
      state.currentPolicy.maxDaysExtended = payload;
    },
    [SET_POLICY_MAX_EXCEPTIONS](state, payload) {
      state.currentPolicy.exceptionLimit = payload;
    },
    [SET_POLICY_MAX_WORKFLOW_USAGE](state, payload) {
      state.currentPolicy.maxWorkflowUsage = payload;
    },
    [SET_POLICY_MIN_DAYS_EXTENDED](state, payload) {
      state.currentPolicy.minDaysExtended = payload;
    },
    [SET_POLICY_NAME](state, payload) {
      state.currentPolicy.name = payload;
    },
    [SET_POLICY_NOTES](state, payload) {
      state.currentPolicy.notes = payload;
    },
    [SET_IS_POLICY_OPTIONS_LOADING](state, payload) {
      state.isPolicyOptionsLoading = payload;
    },
    [SET_POLICY_STATUS](state, payload) {
      state.currentPolicy.isEnabled = payload;
    },
    [SET_POLICY_WORST_CASE_PRICING](state, payload) {
      state.currentPolicy.relockWorstCasePricingThreshold = payload;
    },
    [SET_POLICY_DOCUMENT_REQUIRED_THRESHOLD](state, payload) {
      state.currentPolicy = {
        ...state.currentPolicy,
        documentationRequiredThreshold: payload,
      };
    },
    [SET_POLICY](state, payload) {
      state.currentPolicy = payload;
    },
    [SET_IS_ALL_POLICIES_LOADING](state, payload) {
      state.isAllPoliciesLoading = payload;
    },
    [SET_IS_CUSTOM_POLICIES_LOADING](state, payload) {
      state.isCustomPoliciesLoading = payload;
    },
    [SET_IS_DEFAULT_POLICIES_LOADING](state, payload) {
      state.isDefaultPoliciesLoading = payload;
    },
    [SET_IS_LOCK_DESK_SETTINGS_LOADING](state, payload) {
      state.isLockDeskSettingsLoading = payload;
    },
    [SET_IS_NEW_POLICY](state, payload) {
      state.isNewPolicy = payload;
    },
    [SET_POLICY_TYPE](state, payload) {
      state.currentPolicy.type = payload;
    },
    [SET_POLICY_TYPE_FILTER](state, payload) {
      state.policyTypeFilter = payload;
    },
    [SET_POLICY_PRODUCTS](state, payload) {
      state.currentPolicy.productIds = payload;
    },
    [SET_POLICY_SEQUENCES](state, payload) {
      state.currentPolicy.sequences = payload;
    },
    [SET_IS_POLICY_LOADING](state, payload) {
      state.isPolicyLoading = payload;
    },
    [SET_IS_POLICY_PAGE_LOADING](state, payload) {
      state.isPolicyPageLoading = payload;
    },
    [SET_IS_POLICY_SAVING](state, payload) {
      state.isPolicySaving = payload;
    },
    [SET_PRODUCT_DETAILS_POLICY_OPTIONS](state, payload) {
      state.productDetailPolicyOptions = payload;
    },
    [SET_SEARCH_TEXT](state, payload) {
      state.searchText = payload;
    },
    [SET_LOCK_DESK_SETTINGS](state, payload) {
      state.lockDeskSettings = payload;
    },
    [SET_ACTIVE_WORKFLOW_POLICIES](state, payload) {
      state.activeWorkflowPolicies = payload;
    },
    [SET_SEQUENCE](state, payload) {
      state.reviewalSequence = payload;
    },
    [UPDATE_LEVEL_IN_REVIEWAL_SEQUENCE](state, payload) {
      state.reviewalSequence.levels = payload;
    },
    [SET_REQUESTOR_GROUP_IN_REVIEWAL_SEQUENCE](state, payload) {
      state.reviewalSequence.requestorGroup = payload;
    },
    [SET_APPROVAL_TYPE_IN_REVIEWAL_SEQUENCE](state, payload) {
      state.reviewalSequence.approvalType = payload;
    },
    [SET_EXCEPTION_CEILING_IN_REVIEWAL_SEQUENCE](state, payload) {
      const { index, value } = payload;
      state.reviewalSequence.levels[index].exceptionLimitCeiling = value;
    },
    [SET_NUMBER_OF_APPROVERS_IN_REVIEWAL_SEQUENCE](state, payload) {
      const { index, value } = payload;
      state.reviewalSequence.levels[index].numApprovals = value;
    },
    [SET_APPROVER_GROUP_IN_REVIEWAL_SEQUENCE](state, payload) {
      const { index, value } = payload;
      state.reviewalSequence.levels[index].approvalGroup = value;
    },
    [SET_ORDER_IN_REVIEWAL_SEQUENCE](state, payload) {
      const { index, value } = payload;
      state.reviewalSequence.levels[index].order = value;
    },
    [CLEAR_REVIEWAL_SEQUENCE](state) {
      state.reviewalSequence = { ...emptySequence };
      state.reviewalSequence.levels = [
        {
          approvalGroup: '',
          order: '',
          exceptionLimitCeiling: '',
          numApprovals: '',
        },
      ];
    },
    [ADD_LEVEL_TO_REVIEWAL_SEQUENCE](state) {
      state.reviewalSequence.levels = [
        ...state.reviewalSequence.levels,
        {
          approvalGroup: '',
          order: '',
          exceptionLimitCeiling: '',
          numApprovals: '',
        },
      ];
    },
    [SET_DENY_REASONS](state, payload) {
      state.currentPolicy.denyReasons = payload;
    },
    [SET_IS_REASON_REQUIRED](state, payload) {
      state.currentPolicy.isDenyReasonRequired = payload;
    },
    [SET_IS_REASON_ENABLED](state, payload) {
      state.currentPolicy.isDenyReasonEnabled = payload;
    },
    [SET_RELOCK_DAYS_EXPIRED_ENABLED](state, payload) {
      state.currentPolicy.relockDaysExpiredEnabled = payload;
    },
    [SET_RELOCK_DAYS_EXPIRED_OPERATOR](state, payload) {
      state.currentPolicy = {
        ...state.currentPolicy,
        relockDaysExpiredOperator: payload,
      };
    },
    [SET_RELOCK_DAYS_LOCKED_ENABLED](state, payload) {
      state.currentPolicy.relockDaysLockedEnabled = payload;
    },
    [SET_RELOCK_DAYS_LOCKED_OPERATOR](state, payload) {
      state.currentPolicy = {
        ...state.currentPolicy,
        relockDaysLockedOperator: payload,
      };
    },
    [SET_COMMENT_REQUIRED](state, payload) {
      state.currentPolicy.commentRequired = payload;
    },
  },
  actions: {
    clearCurrentPolicy({ commit }) {
      commit(CLEAR_CURRENT_POLICY);
      commit(SET_IS_POLICY_LOADING, true);
    },
    clearAllPolicies({ commit }) {
      commit(SET_ALL_POLICIES_LIST, []);
    },
    clearPolicyFees({ commit }) {
      commit(SET_POLICY_FEE, null);
      commit(SET_POLICY_FEE_SET, null);
    },
    clearReviewalSequence({ commit }) {
      commit(CLEAR_REVIEWAL_SEQUENCE);
    },
    setSequence({ commit }, payload) {
      commit(SET_SEQUENCE, payload);
    },
    addLevelsToSequence({ commit }) {
      commit(ADD_LEVEL_TO_REVIEWAL_SEQUENCE);
    },
    setLevelsInSequence({ commit }, payload) {
      commit(UPDATE_LEVEL_IN_REVIEWAL_SEQUENCE, payload);
    },
    setRequestorGroupInSequence({ commit }, payload) {
      commit(SET_REQUESTOR_GROUP_IN_REVIEWAL_SEQUENCE, payload);
    },
    setApprovalTypeInSequence({ commit }, payload) {
      commit(SET_APPROVAL_TYPE_IN_REVIEWAL_SEQUENCE, payload);
    },
    setExceptionCeilingInSequence({ commit }, payload) {
      commit(SET_EXCEPTION_CEILING_IN_REVIEWAL_SEQUENCE, payload);
    },
    setApproverGroupInSequence({ commit }, payload) {
      commit(SET_APPROVER_GROUP_IN_REVIEWAL_SEQUENCE, payload);
    },
    setNumOfApprovalsInSequence({ commit }, payload) {
      commit(SET_NUMBER_OF_APPROVERS_IN_REVIEWAL_SEQUENCE, payload);
    },
    setOrderInSequence({ commit }, payload) {
      commit(SET_ORDER_IN_REVIEWAL_SEQUENCE, payload);
    },
    async fetchPEFileUploadSettings({ commit }) {
      try {
        const url = `/pe/api/pe-document-upload-settings`;
        const response = await api.get(url);
        commit(SET_PRICE_EXCEPTION_FILE_UPLOAD_SETTINGS, response);
      } catch (error) {
        onErrorHandler(error, 'pe-document-upload-settings');
      }
    },
    async fetchAllPolicies({ commit }, configId) {
      try {
        const policies = await getEntities(
          configId,
          'lockdeskworkflowpolicies',
        );

        commit(SET_ALL_POLICIES_LIST, policies);

        return policies;
      } catch (error) {
        onErrorHandler(error, 'get-all-lock-desk-workflow-policies');
      } finally {
        commit(SET_IS_ALL_POLICIES_LOADING, false);
      }
    },
    async fetchDefaultPolicyProducts({ commit }, { configId, policyType }) {
      if (!policyType) return;
      const url = `/pe/api/configurations/${configId}/lockdeskworkflowpolicies/${policyType}/products`;

      try {
        const response = await api.get(url);

        commit(SET_DEFAULT_POLICY_PRODUCTS, response.entities);
      } catch (error) {
        onErrorHandler(error, 'pe-get-default-policy-products');
      }
    },
    async deleteCustomPolicy({ commit }, { policyId, configId }) {
      try {
        const response = await api.delete(
          `/pe/api/configurations/${configId}/lockdeskworkflowpolicies`,
          { entity_id: policyId },
        );

        if (isSuccessResponse(response)) {
          commit(SET_POLICY, {});

          return { response, redirect: true };
        }
      } catch (error) {
        onErrorHandler(error, 'lock-desk-delete-policy');
      }
    },
    /**
     * Fetch all default policies associated with current configuration
     * @param {*} commit
     * @param {string} configId
     * @return {[Objects]}
     */
    async fetchDefaultPolicies({ commit }, configId) {
      try {
        const policies = await getEntities(
          configId,
          'lockdeskworkflowpolicies',
          'isDefaultPolicy,True',
          null,
        );

        const defaultPolicies = policies.filter(
          policy => policy.isDefaultPolicy,
        );

        const policiesObject = defaultPolicies.reduce((accum, current) => {
          accum[current.type] = current;

          return accum;
        }, {});

        commit(SET_DEFAULT_POLICIES_LIST, defaultPolicies);
        commit(SET_DEFAULT_POLICIES_OBJECT, policiesObject);

        return defaultPolicies;
      } catch (error) {
        onErrorHandler(error, 'get-lock-desk-workflow-policies');
      } finally {
        commit(SET_IS_DEFAULT_POLICIES_LOADING, false);
      }
    },
    /**
     * Fetch all custom policies associated with current configuration
     * @param {*} commit
     * @param {string} configId
     *
     */
    async fetchCustomPolicies({ commit }, configId) {
      try {
        const policies = await getEntities(
          configId,
          'lockdeskworkflowpolicies',
          'isDefaultPolicy,False',
          null,
        );

        const customPolicies = policies.filter(
          policy => !policy.isDefaultPolicy,
        );

        commit(SET_CUSTOM_POLICIES, customPolicies);
      } catch (error) {
        onErrorHandler(error, 'get-custom-policies');
      } finally {
        commit(SET_IS_CUSTOM_POLICIES_LOADING, false);
      }
    },
    async fetchLockDeskSettings({ commit }, configId) {
      try {
        const response = await api.get(
          `/pe/api/configurations/${configId}/lockdesk-settings`,
        );

        commit(SET_LOCK_DESK_SETTINGS, response);
      } catch (error) {
        onErrorHandler(error, 'get-lock-desk-workflow-settings');
      } finally {
        commit(SET_IS_LOCK_DESK_SETTINGS_LOADING, false);
      }
    },
    /**
     * Fetch policy entity by policyId
     * Returns policy object as well as commits policy to store
     *
     * @param { configId: String, policyId: String, query: Object }
     * @returns Policy
     */
    async getPolicyByPolicyId({ commit }, { configId, policyId, query = {} }) {
      if (!policyId) {
        commit(SET_IS_NEW_POLICY, true);
        commit(SET_POLICY, { ...emptyPolicyBase, ...query });
        commit(SET_IS_POLICY_LOADING, false);

        return;
      }

      try {
        const response = await getEntities(
          configId,
          'lockdeskworkflowpolicies',
          null,
          policyId,
        );

        const policy =
          response.find(currentPolicy => currentPolicy.id === policyId) || {};

        commit(SET_POLICY, { ...emptyPolicyBase, ...policy });

        return policy;
      } catch (error) {
        onErrorHandler(error, 'get-lock-desk-workflow-policies');
      } finally {
        commit(SET_IS_POLICY_LOADING, false);
        commit(SET_IS_POLICY_PAGE_LOADING, false);
      }
    },
    setCommentRequired({ commit }, payload) {
      commit(SET_COMMENT_REQUIRED, payload);
    },
    setCurrentPolicy({ commit }, payload) {
      commit(SET_POLICY, { ...emptyPolicyBase, ...payload });
    },
    setCurrentProductPolicyByType({ commit }, payload) {
      commit(SET_CURRENT_PRODUCT_POLICY_BY_TYPE, payload);
    },
    setPolicyFee({ commit }, payload) {
      commit(SET_POLICY_FEE, payload);
    },
    setPolicyFeeSet({ commit }, payload) {
      commit(SET_POLICY_FEE_SET, payload);
    },
    setPolicyFeeType({ commit }, payload) {
      commit(SET_POLICY_FEE_TYPE, payload);

      if ([FEE_TYPES.SET_OF_FEES].includes(payload)) {
        commit(SET_POLICY_FEE_SET, []);
        commit(SET_POLICY_FEE, null);
      }

      if ([FEE_TYPES.FEE_PER_DAY, FEE_TYPES.FLAT_FEE].includes(payload)) {
        commit(SET_POLICY_FEE_SET, null);
        commit(SET_POLICY_FEE, []);
      }

      if (FEE_TYPES.NONE === payload) {
        commit(SET_POLICY_FEE, null);
        commit(SET_POLICY_FEE_SET, null);
      }
    },
    setPolicyIsAutoApprovalEnabled({ commit }, payload) {
      commit(SET_POLICY_IS_AUTO_APPROVAL_ENABLED, payload);
    },
    setPolicyMaxDaysExtended({ commit }, payload) {
      commit(SET_POLICY_MAX_DAYS_EXTENDED, payload);
    },
    setPolicyMaxExceptions({ commit }, payload) {
      commit(SET_POLICY_MAX_EXCEPTIONS, payload);
    },
    setPolicyMaxWorkflowUsage({ commit }, payload) {
      commit(SET_POLICY_MAX_WORKFLOW_USAGE, payload);
    },
    setPolicyMinDaysExtended({ commit }, payload) {
      commit(SET_POLICY_MIN_DAYS_EXTENDED, payload);
    },
    setPolicyName({ commit }, payload) {
      commit(SET_POLICY_NAME, payload);
    },
    setPolicyNotes({ commit }, payload) {
      commit(SET_POLICY_NOTES, payload);
    },
    setPolicyStatus({ commit }, payload) {
      commit(SET_POLICY_STATUS, payload);
    },
    setPolicyType({ commit, state }, payload) {
      const basePolicyFields = [
        'name',
        'isDefaultPolicy',
        'productIds',
        'isEnabled',
        'notes',
        'createdBy',
        'createdOn',
        'created_name',
      ].reduce((o, k) => ((o[k] = state.currentPolicy?.[k]), o), {});

      commit(SET_POLICY, { ...getEmptyPolicy(payload), ...basePolicyFields });
      commit(SET_POLICY_TYPE, payload);
    },
    setPolicyTypeFilter({ commit }, payload) {
      commit(SET_POLICY_TYPE_FILTER, payload);
    },
    setPolicyWorstCasePricing({ commit }, payload) {
      commit(SET_POLICY_WORST_CASE_PRICING, payload);
    },
    async fetchWorkflowPermissions(
      { commit },
      { configId, productId, useActiveSequences },
    ) {
      const policies = await fetchPolicies(
        configId,
        productId,
        useActiveSequences,
      );
      const floatDownPolicy = await fetchLegacyFloatDownPolicy();

      if (floatDownPolicy) {
        policies.push(floatDownPolicy);
      }
      commit(SET_ACTIVE_WORKFLOW_POLICIES, policies);
    },
    /**
     * Fetch policies and create policy options from the available policies. Use the products'
     * policyIds array to then select the current policy affecting the current product.
     *
     * @param {*} { commit, dispatch }
     * @param {*} { configId, policyIds = [] }
     */
    async setProductDetailPolicyOptions(
      { commit, dispatch },
      { configId, policyIds = [] },
    ) {
      commit(SET_IS_POLICY_OPTIONS_LOADING, true);
      try {
        const defaultPolicies = await dispatch(
          'fetchDefaultPolicies',
          configId,
        );
        const policies = await dispatch('fetchAllPolicies', configId);

        const policiesObject = policies.reduce((accum, current) => {
          if (Array.isArray(accum[current.type])) {
            accum[current.type].push({
              label: current.name,
              value: current.id,
            });
          } else {
            accum[current.type] = [{ label: current.name, value: current.id }];
          }

          return accum;
        }, {});

        commit(SET_PRODUCT_DETAILS_POLICY_OPTIONS, policiesObject);

        if (policyIds?.length) {
          policies
            .filter(policy => policyIds?.includes(policy.id))
            .forEach(policy => {
              commit(SET_CURRENT_PRODUCT_POLICY_BY_TYPE, {
                type: policy.type,
                value: policy.id,
              });
            });
        } else {
          defaultPolicies.forEach(defaultPolicy => {
            commit(SET_CURRENT_PRODUCT_POLICY_BY_TYPE, {
              type: defaultPolicy.type,
              value: defaultPolicy.id,
            });
          });
        }
      } catch (error) {
        onErrorHandler(error, 'get-all-lock-desk-workflow-policies-options');
      } finally {
        commit(SET_IS_POLICY_OPTIONS_LOADING, false);
      }
    },
    setIsCustomPoliciesLoading({ commit }, payload) {
      commit(SET_IS_CUSTOM_POLICIES_LOADING, payload);
    },
    setIsDefaultPoliciesLoading({ commit }, payload) {
      commit(SET_IS_DEFAULT_POLICIES_LOADING, payload);
    },
    setIsPolicyLoading({ commit }, payload) {
      commit(SET_IS_POLICY_LOADING, payload);
    },
    setIsPolicyOptionsLoading({ commit }, payload) {
      commit(SET_IS_POLICY_OPTIONS_LOADING, payload);
    },
    setIsNewPolicy({ commit }, payload) {
      commit(SET_IS_NEW_POLICY, payload);
    },
    setSearchText({ commit }, payload) {
      commit(SET_SEARCH_TEXT, payload);
    },
    setLockDeskSettings({ commit, getters }, payload) {
      const lockDeskSettings = {
        ...getters.lockDeskSettings,
        ...payload,
      };

      commit(SET_LOCK_DESK_SETTINGS, lockDeskSettings);
    },
    /**
     * Save current policy
     *
     * @param {configId: String,
     * policyId: String,
     * hasPriceExceptionManagementEnabled: Boolean,
     * currentPolicyType: String}
     * @returns void
     */
    async saveCurrentPolicy(
      { commit, getters },
      {
        configId,
        policyId,
        hasPriceExceptionManagementEnabled,
        currentPolicyType,
      },
    ) {
      try {
        commit(SET_IS_POLICY_SAVING, true);

        const requestPayload = { ...getters.currentPolicyPayload };

        delete requestPayload.created_name;
        delete requestPayload.isDefaultPolicy;

        // TODO: Delete this when modal is implemented
        // https://pollyex.atlassian.net/browse/PEA-458
        delete requestPayload.productTypeCounts;

        if (
          hasPriceExceptionManagementEnabled === true &&
          currentPolicyType !== WORKFLOW_POLICY_TYPES.FLOAT_REQUEST
        ) {
          delete requestPayload.exceptionLimit;
        }

        if (requestPayload.denyReasons?.length < 1) {
          commit(SET_IS_REASON_ENABLED, false);
        }

        await api.patch(
          `/pe/api/configurations/${configId}/lockdeskworkflowpolicies`,
          {
            entity_id: policyId,
            ...requestPayload,
          },
        );
      } catch (error) {
        onErrorHandler(error, 'update-lockdesk-policies');
      } finally {
        commit(SET_IS_POLICY_SAVING, false);
      }
    },
    async removeProductFromCurrentPolicy(
      { commit, getters, state },
      { productId, configId, policyId },
    ) {
      try {
        const productIds = state.currentPolicy?.productIds || [];
        const filteredProductIds = productIds.filter(id => id !== productId);
        commit(SET_IS_POLICY_SAVING, true);
        commit(SET_POLICY_PRODUCTS, filteredProductIds);

        if (!getters.currentPolicyProductIds.length) {
          commit(SET_POLICY_STATUS, false);
        }

        if (policyId) {
          const requestPayload = { ...getters.currentPolicyPayload };
          await patchPolicy(
            requestPayload,
            requestPayload.productIds.filter(id => id !== productId),
            configId,
            policyId,
          );
        }
      } catch (error) {
        onErrorHandler(error, 'remove-lockdesk-policy-product');
      } finally {
        commit(SET_IS_POLICY_SAVING, false);
      }
    },
    async addProductToCurrentPolicy(
      { commit, getters, state },
      { product, configId, policyId },
    ) {
      try {
        const productIds = state.currentPolicy?.productIds || [];
        commit(SET_IS_POLICY_SAVING, true);
        commit(SET_POLICY_PRODUCTS, [...productIds, product.id]);
        if (policyId) {
          const requestPayload = { ...getters.currentPolicyPayload };
          await patchPolicy(
            requestPayload,
            [...requestPayload.productIds, product.id],
            configId,
            policyId,
          );
        }
      } catch (error) {
        onErrorHandler(error, 'remove-lockdesk-policy-product');
      } finally {
        commit(SET_IS_POLICY_SAVING, false);
      }
    },
    async bulkModifyProductsInCurrentPolicy(
      { commit, getters },
      { productIds, configId, policyId },
    ) {
      try {
        commit(SET_IS_POLICY_SAVING, true);
        commit(SET_POLICY_PRODUCTS, productIds);
        if (policyId) {
          const requestPayload = { ...getters.currentPolicyPayload };
          await patchPolicy(requestPayload, productIds, configId, policyId);
        }
      } catch (error) {
        onErrorHandler(error, 'remove-lockdesk-policy-product');
      } finally {
        commit(SET_IS_POLICY_SAVING, false);
      }
    },
    async addSequenceToCurrentPolicy({ commit, state }, { newSequence }) {
      try {
        const sequences = state.currentPolicy?.sequences || [];

        commit(SET_IS_POLICY_SAVING, true);

        commit(SET_POLICY_SEQUENCES, [...sequences, newSequence]);
      } catch (error) {
        onErrorHandler(error, 'add-lockdesk-policy-sequence');
      } finally {
        commit(SET_IS_POLICY_SAVING, false);
      }
    },

    async removeSequenceFromCurrentPolicy(
      { commit, state },
      { removeSequenceId },
    ) {
      try {
        const sequence = state.currentPolicy?.sequences || [];
        commit(SET_IS_POLICY_SAVING, true);

        const remainingSequenceIds = sequence.filter(sequence => {
          return sequence.id !== removeSequenceId;
        });

        commit(SET_POLICY_SEQUENCES, remainingSequenceIds);
      } catch (error) {
        onErrorHandler(error, 'remove-lockdesk-policy-sequence');
      } finally {
        commit(SET_IS_POLICY_SAVING, false);
      }
    },
    /**
     * Fetch and clone policy by id
     * id, isDefaultPolicy are not necessary to post for a new cloned product
     * productIds is removed to prevent cloned products from auto including prior products
     * isEnabled is set to false during this step becuase products are removevd
     *
     * @param { configId: String, policyId: String }
     * @returns { response: Object, redirect Boolean, id: String }
     */
    async cloneCustomPolicy({ commit }, { configId, policyId }) {
      try {
        commit(SET_IS_POLICY_PAGE_LOADING, true);

        const policyResponse = await getEntities(
          configId,
          'lockdeskworkflowpolicies',
          null,
          policyId,
        );

        const policy =
          policyResponse.find(currentPolicy => currentPolicy.id === policyId) ||
          {};

        delete policy.id;
        delete policy.isDefaultPolicy;
        delete policy.productIds;

        // Convert sequences into a list of their IDs needed for saving/creating the policy
        policy.sequences = policy?.sequences?.map(sequence => sequence.id);

        policy.isEnabled = false;

        policy.name = await getUniqueEntityCopyName(
          configId,
          policy.name,
          'lockdeskworkflowpolicie',
        );

        const response = await api.post(
          `/pe/api/configurations/${configId}/lockdeskworkflowpolicies`,
          { ...policy },
        );

        if (isSuccessResponse(response)) {
          return {
            response,
            redirect: true,
            policyId: response?.messages[0]?.data,
          };
        }
      } catch (error) {
        onErrorHandler(error, 'clone-custom-lockdesk-workflow-policy');
      } finally {
        commit(SET_IS_POLICY_PAGE_LOADING, false);
      }
    },
    /**
     * Create new custom policy from current policy state
     *
     * @param { configId: String }
     * @returns id: String
     */
    async createCustomPolicy({ commit, getters, dispatch }, { configId }) {
      try {
        commit(SET_IS_POLICY_PAGE_LOADING, true);

        const response = await api.post(
          `/pe/api/configurations/${configId}/lockdeskworkflowpolicies`,
          { ...getters.currentPolicyPayload },
        );

        const { data: policyId, type } = response?.messages?.[0] || {};

        if (policyId && type === 'success') {
          commit(SET_IS_NEW_POLICY, false);

          await dispatch('getPolicyByPolicyId', { configId, policyId });

          return policyId;
        }
      } catch (error) {
        onErrorHandler(error, 'create-new-lockdesk-workflow-policy');
      } finally {
        commit(SET_IS_POLICY_PAGE_LOADING, false);
      }
    },

    setRelockDaysExpiredEnabled({ commit }, payload) {
      commit(SET_RELOCK_DAYS_EXPIRED_ENABLED, payload);
    },
    setRelockDaysLockedEnabled({ commit }, payload) {
      commit(SET_RELOCK_DAYS_LOCKED_ENABLED, payload);
    },
    setRelockDaysExpiredOperator({ commit }, payload) {
      commit(SET_RELOCK_DAYS_EXPIRED_OPERATOR, payload);
    },
    setRelockDaysLockedOperator({ commit }, payload) {
      commit(SET_RELOCK_DAYS_LOCKED_OPERATOR, payload);
    },
  },
};
